import { SearchField } from '@/components';
import { ComponentLoader } from '@/components/ComponentLoader';
import { useGetAllFinanciers, useGetTableSettings } from '@/hooks';
import { Stack } from '@mui/material';
import { useDebouncedState } from '@treyd-io/core/hooks/useDebouncedState';
import {
  Filter,
  FilterComparator,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { isEmpty, isNaN, toNumber, toString } from 'lodash';
import { OrdersTable } from './OrdersTable';
import { TableFilters } from './TableFilters';

export const AllOrdersTable = () => {
  const [searchTerm, setSearchTerm] = useDebouncedState('', 500);
  const {
    ordersReviewAllOrdersTableSettings: {
      filterFields,
      setFilterFields,
      resetTable,
    },
  } = useGetTableSettings();

  const { data, loading: isAllFinanciersLoading } = useGetAllFinanciers();
  const allFinanciers = data?.orders_financiers;

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(toString(searchTerm).trim())
          ? null
          : toNumber(searchTerm),
    },
    {
      type: 'search',
      name: 'importer__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
  ];
  const filters: Filter[] = [...searchFields, ...filterFields];

  if (isAllFinanciersLoading) return <ComponentLoader />;

  return (
    <Stack flexGrow={1} gap={3} height="100%">
      <Row>
        <Col xs={12} sm>
          <SearchField
            placeholder={'Search by order #, or merchant name'}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Col>
        <Col xs={12} sm="auto">
          <TableFilters
            onSubmit={setFilterFields}
            onReset={resetTable}
            filters={filterFields}
            allFinanciers={allFinanciers}
          />
        </Col>
      </Row>
      <OrdersTable filters={filters} />
    </Stack>
  );
};
