import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, ROUTES } from '@/constants';
import { useGetEmailsLogs } from '@/hooks';
import { EmailLog } from '@/schemas/getEmailsLogsQuery';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { lowerCase, startCase, toString } from 'lodash';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

export const EmailsTable = ({ filters }: { filters?: Filter[] }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'desc' },
  ]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const columns: GridColDef<EmailLog>[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Email #',
        minWidth: 100,
        renderCell: (params) => {
          return (
            <Link to={`${ROUTES.emails}/${params.id}`}>{params.value}</Link>
          );
        },
      },
      {
        field: 'template__name',
        headerName: 'Template',
        minWidth: 300,
        flex: 1,
        renderCell: (params) => (
          <Typography noWrap>
            {startCase(lowerCase(toString(params.row?.template?.name)))}
          </Typography>
        ),
      },
      {
        field: 'recipient',
        headerName: 'Recipient',
        flex: 1,
        minWidth: 250,
        renderCell: (params) => <Typography noWrap>{params.value}</Typography>,
      },
      {
        field: 'created',
        headerName: 'Creation date',
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) =>
          params.value && formatDate(new Date(params.value), 'date'),
      },
      {
        field: 'delivered_at',
        headerName: 'Delivered at',
        minWidth: 140,
        renderCell: (params) =>
          params.value && formatDate(new Date(params.value), 'datetime'),
      },
      {
        field: 'first_opened_at',
        headerName: 'First opened at',
        minWidth: 140,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) =>
          params.value && formatDate(new Date(params.value), 'datetime'),
      },
      {
        field: 'last_opend_at',
        headerName: 'Last opened at',
        minWidth: 140,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) =>
          params.value && formatDate(new Date(params.value), 'datetime'),
      },
      {
        field: 'open_count',
        headerName: 'Open count',
        align: 'center',
        flex: 0.25,
        minWidth: 100,
        renderCell: (params) => <Typography noWrap>{params.value}</Typography>,
      },
    ],
    []
  );

  const { data, loading: isEmailsLoading } = useGetEmailsLogs({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: pageSize,
      offset: page * pageSize,
    },
  });

  const emails = data?.orders_notification_center_emaillog || [];
  const rowCount =
    data?.orders_notification_center_emaillog_aggregate?.aggregate?.count || 0;

  const noEmailsProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack flexGrow={1} height={400} width="100%">
      <DataGrid
        rows={emails}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCount}
        pageSize={pageSize}
        page={page}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        sortModel={sortModel}
        loading={isEmailsLoading}
        onSortModelChange={setSortModel}
        components={{
          NoRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'No emails found'}
              subtitle={'Emails will be displayed here'}
            />
          ),
          ...noEmailsProps,
        }}
        {...noEmailsProps}
      />
    </Stack>
  );
};
