import { EU_COMPANY_AGREEMENTS, TOS, UK_COMPANY_AGREEMENTS } from '@/constants';
import { NORDIC_COUNTRIES } from '@treyd-io/core/constants/country';
import { values } from 'lodash';

export namespace ConsentService {
  export const getTOS = () => {
    return TOS;
  };

  export const getCompanyAgreementLink = (
    country: string,
    version_number?: number
  ) => {
    if (!version_number) return null;
    if (NORDIC_COUNTRIES.includes(country)) {
      return values(EU_COMPANY_AGREEMENTS)[version_number - 1];
    }
    return UK_COMPANY_AGREEMENTS.version_1;
  };
}
