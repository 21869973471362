import { formatDate } from '@treyd-io/core/utils/date';
import { getYear, subMonths, subYears } from 'date-fns';

export namespace FinancialDocumentsService {
  const today = new Date();
  const todayLastYear = subYears(new Date(), 1);

  export const fiscalYearInfo = {
    currentFiscalYear: getYear(today),
    currentFiscalYearFormula: formatDate(
      subMonths(today, 3),
      'longDateWithoutDay'
    ),
    lastFiscalYear: getYear(todayLastYear),
    lastFiscalYearFormula: formatDate(
      subMonths(todayLastYear, 3),
      'longDateWithoutDay'
    ),
  };
}
