import { ROUTES } from '@/constants';
import { OrdersCompany } from '@/schemas/getGlobalSearchResults';
import { Chip, Link as MuiLink, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatCurrency } from '@treyd-io/core/utils/number';
import BuildingStoreIcon from '@treyd-io/treyd-ui/icons/BuildingStoreIcon';
import { isEmpty, isNil, toNumber, toString } from 'lodash';
import { Link } from 'react-router-dom';
import { MerchantWithLink } from '../MerchantWithLink';

const columns: GridColDef<OrdersCompany>[] = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    minWidth: 200,
    flex: 1,
    renderCell: (params) => {
      const id = params.row?.id;
      const name = params.row?.name;
      const segment = params.row?.segment;

      return name && <MerchantWithLink id={id} name={name} segment={segment} />;
    },
  },
  {
    field: 'org_no',
    headerName: 'Org No',
    sortable: false,
    flex: 1,
  },
  {
    field: 'country',
    headerName: 'Country',
    sortable: false,
    flex: 1,
    valueGetter: (params) => getCountryName(params.value),
  },
  {
    field: 'financier__name',
    headerName: 'Financier',
    sortable: false,
    minWidth: 200,
    valueGetter: ({ row }) => toString(row.financier?.name),
  },
  {
    field: 'is_active',
    headerName: 'Status',
    width: 150,
    sortable: false,

    renderCell: (params) => {
      const { is_active, is_funding_secured } = params.row;
      if (isNil(is_funding_secured)) return <></>;
      return (
        <Chip
          label={is_active ? 'Active' : 'Inactive'}
          color={is_active ? 'success' : 'error'}
          size="small"
          variant="standard"
        />
      );
    },
  },
  {
    field: 'limit',
    headerName: 'Credit limit',
    minWidth: 200,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'tabular-numbers',
    valueGetter: (params) =>
      formatCurrency(params.value, toString(params.row?.limit_currency)),
  },
];

export const MerchantsTable = ({
  merchants,
  searchTerm,
}: {
  merchants?: OrdersCompany[];
  searchTerm?: string;
}) => {
  if (isEmpty(merchants)) return null;

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <BuildingStoreIcon />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          flexWrap="wrap">
          <Typography variant="body1">Merchants</Typography>
          <MuiLink
            component={Link}
            to={`${ROUTES.merchants}?searchTerm=${searchTerm}`}
            variant="body2">
            Show more ↗︎
          </MuiLink>
        </Stack>
      </Stack>
      <Stack height={toNumber(merchants?.length) <= 4 ? 'auto' : 185}>
        <DataGrid
          rows={merchants || []}
          columns={columns}
          disableSelectionOnClick
          hideFooter
          autoHeight={toNumber(merchants?.length) <= 4}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.columnHeader}:focus`]:
              {
                outline: 'none',
              },
          }}
        />
      </Stack>
    </Stack>
  );
};
