import { DEFAULT_GRID_COL_DEF } from '@/constants';
import { MemberType } from '@/schemas';
import { Box, Typography } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

interface MembersTableProps {
  members: MemberType[];
}

export const MembersTable = (props: MembersTableProps) => {
  const columns = [
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'id',
      headerName: 'Member ID',
      width: 150,
    },
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'name',
      headerName: 'Name',
      type: 'string',
      width: 250,
    },
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'can_sign_alone',
      headerName: 'Can Sign Alone',
      type: 'string',
      width: 250,
      renderCell: (params: any) => (
        <div>
          {params.row.core_signatories[0]?.can_sign_alone ? 'Yes' : 'No'}
        </div>
      ),
    },
  ];

  return (
    <Box sx={(theme) => ({ marginBlockEnd: theme.spacing(2.5) })}>
      <Typography
        sx={(theme) => ({ marginBlockEnd: theme.spacing(1) })}
        variant="h5">
        Members
      </Typography>
      <DataGrid
        rows={props.members}
        columns={columns}
        pageSize={5}
        autoHeight={true}
        rowsPerPageOptions={[5]}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within, & .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            { outline: 'none' },
        }}
      />
    </Box>
  );
};
