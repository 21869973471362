import SearchField from '@/components/SearchField';

import { Box } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { startCase } from 'lodash';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_GRID_COL_DEF, DEV_PATHS } from '@/constants';
import { Event } from '@/schemas';

export interface EventsTableProps {
  events: Event[];
  user_id?: number;
  org_no?: string;
}
const columns = [
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'type',
    headerName: 'Type',
    width: 300,
    renderCell: (params: any) => startCase(params.value),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'created',
    headerName: 'Created',
    width: 120,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'ack',
    headerName: 'Acknowledged',
    width: 140,
    renderCell: (params: any) => (params.value ? 'Yes' : 'No'),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'invocations',
    headerName: 'Has invocations',
    width: 140,
    renderCell: (params: any) => (params.value?.length > 0 ? 'Yes' : 'No'),
  },
];
export const EventsTable = (props: EventsTableProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const navigate = useNavigate();

  const formatType = (type: string) => type.replaceAll('_', ' ');

  const getFilteredUsers = useCallback(() => {
    if (searchTerm === '') return props.events;
    return props.events?.filter((event) =>
      formatType(event.type).toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }, [props.events, searchTerm]);

  const handleRowClick = useCallback(
    (params: any) => {
      navigate(DEV_PATHS['event_details'].replaceAll(':event_id', params.id), {
        state: {
          event: params.row,
          user_id: props.user_id,
          org_no: props.org_no,
        },
      });
    },
    [navigate, props.org_no, props.user_id]
  );

  return (
    <Box sx={{ width: '100%', height: '500px', marginBlock: '2rem' }}>
      <Box
        sx={{ width: '33%', marginInlineEnd: '1.5rem', marginBlock: '1rem' }}>
        <SearchField
          placeholder="search for events by type..."
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </Box>
      <DataGrid
        rows={getFilteredUsers()}
        getRowId={(row) => row.uid}
        columns={columns}
        pageSize={10}
        onRowClick={handleRowClick}
        rowsPerPageOptions={[5]}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within, & .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            { outline: 'none' },
        }}
      />
    </Box>
  );
};
