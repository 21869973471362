import { SearchField } from '@/components';
import { ComponentLoader } from '@/components/ComponentLoader';
import { CurrenciesTable } from '@/components/Invoices/CurrenciesTable';
import { ExportInvoicesButton } from '@/components/Invoices/ExportInvoicesButton';
import { FinanciersTable } from '@/components/Invoices/FinanciersTable';
import { INVOICE_TYPE } from '@/constants';
import { useGetAllFinanciers } from '@/hooks';
import useSearchTerm from '@/hooks/useSearchTerm';
import { Stack } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QueryFilterField,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { isEmpty, isNaN, toNumber, toString } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvoicesTable } from './InvoicesTable';
import { TableFilters } from './TableFilters';

export const Invoices = () => {
  const { id } = useParams();
  const companyId =
    isNaN(toNumber(id)) || isEmpty(id) ? undefined : toNumber(id);

  const { searchTerm, setSearchTerm } = useSearchTerm('invoicesSearchTerm');

  const { data, loading: isAllFinanciersLoading } = useGetAllFinanciers();
  const allFinanciers = data?.orders_financiers;

  const [filterFields, setFilterFields] = useState<QueryFilterField[]>([
    {
      type: 'filter',
      name: 'invoice_type',
      comparator: FilterComparator.IN,
      comparisonValue: [INVOICE_TYPE.DEBTOR],
    },
  ]);

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'invoice_number',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
    {
      type: 'search',
      name: 'order_id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(toString(searchTerm).trim())
          ? null
          : toNumber(searchTerm),
    },
  ];
  const filters: Filter[] = [
    ...searchFields,
    ...filterFields,
    {
      type: 'filter',
      name: 'order__importer_id',
      comparator: FilterComparator.EQUAL,
      comparisonValue: companyId,
    },
  ];

  if (isAllFinanciersLoading) return <ComponentLoader />;

  return (
    <Stack gap={3}>
      <Stack gap={2}>
        <Row>
          <Col xs={12} sm>
            <SearchField
              placeholder={'Search by invoice #, or order #'}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Col>
          <Col xs={12} sm="auto">
            <TableFilters
              onSubmit={setFilterFields}
              allFinanciers={allFinanciers}
              appliedFilterFieldsCount={filterFields.length}
            />
          </Col>
          <Col xs={12} sm="auto">
            <ExportInvoicesButton filters={filters} enableExportTransactions />
          </Col>
        </Row>
        <InvoicesTable filters={filters} />
      </Stack>
      <CurrenciesTable filters={filters} />
      <FinanciersTable filters={filters} allFinanciers={allFinanciers} />
    </Stack>
  );
};
