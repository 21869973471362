import { MerchantWithLink } from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import {
  CreditableType,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  creditableTypeDisplayedValue,
} from '@/constants';
import { useGetCredits, useGetTableSettings } from '@/hooks';
import { Credit } from '@/schemas/getCreditsQuery';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { startCase, toNumber, toString } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export const CreditsTable = ({ filters }: { filters?: Filter[] }) => {
  const {
    creditsTableSettings: {
      page,
      pageSize,
      sortModel,
      setPage,
      setPageSize,
      setSortModel,
    },
  } = useGetTableSettings();

  const columns: GridColDef<Credit>[] = useMemo(
    () => [
      {
        field: 'company__name',
        headerName: 'Merchant',
        flex: 1,
        minWidth: 300,
        renderCell: (params) => {
          const companyName = params.row?.company?.name;
          const companyId = params.row?.company?.id;
          const companySegment = params.row?.company?.segment;
          return (
            companyName && (
              <MerchantWithLink
                id={companyId}
                name={companyName}
                segment={companySegment}
              />
            )
          );
        },
      },
      {
        field: 'reason',
        headerName: 'Reason',
        flex: 1,
        minWidth: 250,
        renderCell: (params) => startCase(params.value),
      },
      {
        field: 'creditable_type',
        headerName: '',
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: (params) => {
          const creditableType = params?.row?.creditable_type;
          const creditable = {
            [CreditableType.INVOICE]: (
              <Link to={`${ROUTES.invoices}/${params?.row?.order_invoice_id}`}>
                {params?.row?.invoice_number}
              </Link>
            ),
            [CreditableType.VOUCHER]: params?.row?.slug,
          };
          return (
            <Stack direction="row" gap={0.5}>
              <Typography variant="body2">
                {creditableTypeDisplayedValue[creditableType]}:
              </Typography>
              <Typography variant="body2">
                {creditable[creditableType]}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: 'created',
        headerName: 'Creation date',
        minWidth: 120,
        flex: 1,
        renderCell: (params) =>
          params.value && formatDate(new Date(params.value), 'date'),
      },
      {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 150,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) =>
          formatCurrency(
            toNumber(params.value),
            toString(params.row?.company?.invoicing_currency)
          ),
      },
    ],
    []
  );

  const { data, loading: isCreditsLoading } = useGetCredits({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: pageSize,
      offset: page * pageSize,
    },
  });

  const credits = data?.orders_voucher_credits_invoices || [];
  const rowCount =
    data?.orders_voucher_credits_invoices_aggregate?.aggregate?.count || 0;

  const noCreditsProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack flexGrow={1} height={!rowCount ? 400 : 'auto'} width="100%">
      <DataGrid
        rows={credits}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCount}
        pageSize={pageSize}
        page={page}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        sortModel={sortModel}
        loading={isCreditsLoading}
        onSortModelChange={setSortModel}
        components={{
          NoRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'No credits found'}
              subtitle={'Credits will be displayed here'}
            />
          ),
          ...noCreditsProps,
        }}
        {...noCreditsProps}
      />
    </Stack>
  );
};
