import { Unified_Supplier_Invoice } from '@/__generated__/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';

export type MerchantSupplierErpInvoice = Pick<
  Unified_Supplier_Invoice,
  | 'uuid'
  | 'invoice_reference'
  | 'issue_date'
  | 'due_date'
  | 'invoice_status'
  | 'total_amount'
  | 'currency'
  | 'balance'
  | 'supplier_name'
>;

type GetMerchantSuppliersErpInvoicesQueryType = {
  unified_supplier_invoice: MerchantSupplierErpInvoice[];
  unified_supplier_invoice_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type GetMerchantSuppliersErpInvoicesQueryVariables = {
  erpConnectionId: string;
};

export const getMerchantSuppliersErpInvoicesQuery = (
  queryOptions?: QueryOptions
) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);
  return gql`
    query getMerchantSuppliersErpInvoicesQuery {
      unified_supplier_invoice ${queryParams} {
        uuid
        invoice_reference
        issue_date
        due_date
        invoice_status
        total_amount
        currency
        balance
        supplier_name
      }
      unified_supplier_invoice_aggregate  ${
        whereClause ? `(where: ${whereClause})` : ''
      }  {
        aggregate {
          count
        }
      }
    }
  ` as TypedDocumentNode<GetMerchantSuppliersErpInvoicesQueryType, {}>;
};
