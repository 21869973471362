import { OrderStatusChip } from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import {
  MAPPED_ORDER_STATUS,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  ROUTES,
} from '@/constants';
import { useGetOrders } from '@/hooks/useGetOrders';
import { Order } from '@/schemas/getOrdersQuery';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toString } from 'lodash';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

export const OrdersTable = ({ filters }: { filters?: Filter[] }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'desc' },
  ]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const { data, loading: isLoadingOrders } = useGetOrders({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: pageSize,
      offset: page * pageSize,
    },
  });

  const orders = data?.orders_orders || [];
  const rowCount = data?.orders_orders_aggregate?.aggregate?.count || 0;

  const noOrdersProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  const columns: GridColDef<Order>[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Order #',
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          const isDiscardedOrder =
            params.row?.mapped_status === MAPPED_ORDER_STATUS.DISCARDED;
          if (isDiscardedOrder)
            return (
              <Typography
                component="del"
                variant="body2"
                color="text.secondary">
                {params.value}
              </Typography>
            );
          return (
            <Typography
              component={Link}
              to={`${ROUTES.orders}/${params.id}`}
              noWrap
              variant="body2">
              {params.value}
            </Typography>
          );
        },
      },
      {
        field: 'mapped_status',
        headerName: 'Status',
        minWidth: 190,
        renderCell: ({ value }) => (
          <OrderStatusChip size="small" status={value} />
        ),
      },

      {
        field: 'financier__name',
        headerName: 'Financier',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => params.row?.financier?.name,
      },
      {
        field: 'created',
        headerName: 'Creation date',
        minWidth: 150,
        renderCell: ({ value }) => {
          return value && formatDate(new Date(value), 'date');
        },
      },
      {
        field: 'active_payout__expected_payment_date',
        headerName: 'Expected payment date',
        minWidth: 150,
        renderCell: (params) => {
          const date = params.row?.active_payout?.expected_payment_date;
          return date && formatDate(new Date(date), 'date');
        },
      },
      {
        field: 'active_payout__expected_settlement_date_calc',
        headerName: 'Expected repayment date',
        minWidth: 150,
        renderCell: (params) => {
          const date = params.row?.active_payout?.expected_settlement_date_calc;
          return date && formatDate(new Date(date), 'date');
        },
      },
      {
        field: 'active_payout__actual_payment_date',
        headerName: 'Actual payment date',
        minWidth: 150,
        renderCell: (params) => {
          const date = params.row?.active_payout?.actual_payment_date;
          return date && formatDate(new Date(date), 'date');
        },
      },
      {
        field: 'active_payout__amount',
        headerName: 'Payout amount',
        minWidth: 150,
        flex: 1,
        cellClassName: 'tabular-numbers',
        renderCell: (params) => {
          const amount = params.row?.active_payout?.amount;
          const currency = params.row?.active_payout?.currency;
          return formatCurrency(amount, toString(currency));
        },
      },
      {
        field:
          'orders_totals_in_invoicing_currency___total_amount_inc_vat_in_invoicing_currency',
        headerName: 'Repayment amount',
        minWidth: 150,
        flex: 1,
        cellClassName: 'tabular-numbers',
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          const amount =
            params.row?.orders_totals_in_invoicing_currency
              ?.total_amount_inc_vat_in_invoicing_currency;
          const currency = params.row?.invoicing_currency;
          return formatCurrency(amount, toString(currency));
        },
      },
    ],
    []
  );
  return (
    <Stack flexGrow={1} height={400} width="100%">
      <DataGrid
        rows={orders}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        onPageChange={setPage}
        rowCount={rowCount}
        pageSize={pageSize}
        page={page}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        onPageSizeChange={setPageSize}
        sortModel={sortModel}
        loading={isLoadingOrders}
        onSortModelChange={setSortModel}
        components={{
          NoRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'Nothing here yet'}
              subtitle={'Orders will be displayed here'}
            />
          ),
          ...noOrdersProps,
        }}
        {...noOrdersProps}
      />
    </Stack>
  );
};
