import { SearchField } from '@/components';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import useSearchTerm from '@/hooks/useSearchTerm';
import { ErpInvoices } from '@/types';
import { Stack, Typography } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QueryFilterField,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { useState } from 'react';
import { SuppliersInvoicesTable } from './SuppliersInvoicesTable';
import { TableFilters } from './TableFilters';

export const ErpInvoicesTab = ({
  erpConnectionId,
}: {
  erpConnectionId: string;
}) => {
  const { searchTerm, setSearchTerm } = useSearchTerm('ordersSearchTerm');
  const [filterFields, setFilterFields] = useState<
    QueryFilterField<ErpInvoices.InvoicesTableFiltersFields>[]
  >([]);

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'invoice_reference',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'supplier_name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
  ];

  const filters: Filter[] = [
    ...searchFields,
    ...filterFields,
    {
      type: 'filter',
      name: 'erp_connection_id',
      comparator: FilterComparator.EQUAL,
      comparisonValue: erpConnectionId,
    },
  ];

  return (
    <Stack gap={3}>
      <Typography variant="h5">Suppliers Invoices</Typography>
      <Stack gap={5} height="100%">
        <Stack gap={3} height="100%">
          <Row>
            <Col xs={12} sm>
              <SearchField
                placeholder={'Search by invoice reference, or supplier name'}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </Col>
            <Col xs={12} sm="auto">
              <TableFilters
                onSubmit={setFilterFields}
                onReset={() => setFilterFields([])}
                filters={filterFields}
              />
            </Col>
          </Row>
          <SuppliersInvoicesTable filters={filters} />
        </Stack>
      </Stack>
    </Stack>
  );
};
