import { SearchField } from '@/components';
import { ComponentLoader } from '@/components/ComponentLoader';
import { ExportOrdersButton } from '@/components/Orders/ExportOrdersButton';
import { useGetAllFinanciers, useGetTableSettings } from '@/hooks';
import useSearchTerm from '@/hooks/useSearchTerm';
import { Stack, Typography } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { isEmpty, isNaN, toNumber, toString } from 'lodash';
import { OrdersTable } from './OrdersTable';
import { TableFilters } from './TableFilters';

export const OrdersPage = () => {
  const { searchTerm, setSearchTerm } = useSearchTerm('ordersSearchTerm');
  const {
    ordersTableSettings: { filterFields, setFilterFields, resetTable },
  } = useGetTableSettings();

  const { data, loading: isAllFinanciersLoading } = useGetAllFinanciers();
  const allFinanciers = data?.orders_financiers;

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(toString(searchTerm).trim())
          ? null
          : toNumber(searchTerm),
    },
    {
      type: 'search',
      name: 'importer__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
  ];
  const filters: Filter[] = [...searchFields, ...filterFields];

  if (isAllFinanciersLoading) return <ComponentLoader />;

  return (
    <Stack flexGrow={1} gap={5} height="100%">
      <Stack flexGrow={1} gap={3} height="100%">
        <Row>
          <Col xs="auto">
            <Typography variant="h4">Orders</Typography>
          </Col>
          <Col xs={12} sm>
            <SearchField
              placeholder={'Search by order #, or merchant name'}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Col>
          <Col xs={12} sm="auto">
            <TableFilters
              onSubmit={setFilterFields}
              onReset={resetTable}
              filters={filterFields}
              allFinanciers={allFinanciers}
            />
          </Col>
          <Col xs={12} sm="auto">
            <ExportOrdersButton filters={filters} />
          </Col>
        </Row>
        <OrdersTable filters={filters} />
      </Stack>
    </Stack>
  );
};
