// eslint-disable-next-line no-undef
const envs = process.env;

const {
  REACT_APP_ENV,
  REACT_APP_LAGER_HOST,
  REACT_APP_HASURA_HOST,
  REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
} = envs;

export const ENV = REACT_APP_ENV;
export const HASURA_HOST = REACT_APP_HASURA_HOST;
export const LAGER_API_HOST = REACT_APP_LAGER_HOST;
export const GOOGLE_LOGIN_CLIENT_ID = REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
export const LAGER_GOOGLE_AUTH = `/auth/google/admin/login/`;
export const LAGER_CURRENT_USER = '/auth/token/verify-current-user/';
export const COMPANY_ONBOARDING_STATUSES = (companyId: string) =>
  `/api/companies/${companyId}/onboarding-statuses/`;
export const ORDERS_URL = '/api/order';
export const DEAL_DUPLICATES = (orderId: number) =>
  `/api/order/${orderId}/has-duplicate/`;
export const SELF_ASSIGN_ORDER = (order_id: number) =>
  `${ORDERS_URL}/${order_id}/assign-reviewer/`;
export const ADD_COMMENT = (order_id: number) =>
  `${ORDERS_URL}/${order_id}/comment`;
export const DELETE_COMMENT = (commentId: number) =>
  `/api/comment/${commentId}`;
export const SUBMIT_ORDER_REVIEW = (order_id: number) =>
  `${ORDERS_URL}/${order_id}/submit-review/`;
export const SUBMIT_ORDER_COMPLIANCE_REVIEW = (order_id: number) =>
  `${ORDERS_URL}/${order_id}/submit-compliance-review/`;
export const SUBMIT_ORDER_FUNDING_REVIEW = (order_id: number) =>
  `${ORDERS_URL}/${order_id}/submit-funding-review/`;
export const AUTO_ORDER_COMPLIANCE_REVIEW = (order_id: number) =>
  `${ORDERS_URL}/${order_id}/auto-compliance-review/`;
export const EDIT_ORDER_REVIEW = (order_id: number) =>
  `${ORDERS_URL}/${order_id}/edit-review-data/`;
export const AUTO_ORDER_REVIEW = (order_id: number) =>
  `${ORDERS_URL}/${order_id}/auto-order-review/`;

export const ORDER_EVENTS_URL = (orderId: number, eventName: string) =>
  `/api/order/${orderId}/sm_${eventName}/`;

export const ORDER_SM_CAN = (orderId: number, eventName: string) =>
  `/api/order/${orderId}/sm_can_${eventName}/`;
export const ORDER_LOGS_VISUALIZATION = (orderId: number) =>
  `${ORDERS_URL}/${orderId}/mermaid/`;

export const ORDER = (orderId: number) => `${ORDERS_URL}/${orderId}/`;

export const INVOICES = (invoiceId: number) =>
  `/api/order/invoices/${invoiceId}/`;
export const ADD_NOTIFICATION_GROUP = '/api/notifications/group';
export const EDIT_NOTIFICATION_GROUP = (groupId: number) =>
  `/api/notifications/group/${groupId}`;
export const PUBLISH_NOTIFICATION_GROUP = (group_id: number) =>
  `/api/notifications/group/${group_id}/publish`;
export const UNPUBLISH_NOTIFICATION_GROUP = (group_id: number) =>
  `/api/notifications/group/${group_id}/unpublish`;
export const USER_REFRESH_TOKEN = '/auth/token/refresh/';
export const USER_INVITE = '/auth/invitation/send/';
export const protocol =
  ENV === 'development'
    ? { http: 'http', ws: 'ws' }
    : { http: 'https', ws: 'wss' };

export const CURRENCIES_URL = '/api/order/allowed-currencies/';
export const COMPANY_LIMIT_URL = (orderId: number) =>
  `/api/order/${orderId}/available-limits/`;

export const NEXT_REPAYMENT_INVOICE = (orderId: number) =>
  `/api/order/${orderId}/next-repayment/`;

export const DOCUMENTS_URL = (
  documentableType: string,
  documentableId: number
) => `/api/${documentableType}/${documentableId}/documents/`;

export const DOCUMENTS_RESEND_EMAILS = (
  documentableType: string,
  documentableId: number,
  documentId: number
) =>
  `${DOCUMENTS_URL(
    documentableType,
    documentableId
  )}${documentId}/resend_emails/`;

export const ORDER_INVOICES_URL = (orderId: number) =>
  `/api/order/${orderId}/invoices/`;

export const COMPANY_FEES = (companyId: number) =>
  `/api/companies/${companyId}/pricing/`;

export const COMPANY_APPLIED_DISCOUNTS = (companyId: number) =>
  `/api/companies/${companyId}/applied-discounts/`;

export const COMPANY_MANUAL_DISCOUNT_CHARGES = (companyId: number) =>
  `/api/companies/${companyId}/manual-discount-charges/`;
export const COMPANY_ATTORNEY = (companyId: number) =>
  `/api/companies/${companyId}/power_of_attorneys/`;

export const PREVIEW_AML_PDF = '/api/risk/aml/latest/pdf/';

export const REMAINING_LIMIT = (companyId: number) =>
  `/api/companies/${companyId}/available-limits/`;

export const LIMIT_STATUS = (companyId: number) =>
  `/api/companies/${companyId}/limit-status/`;

//hubspot
export const HUBSPOT_IDS = `/api/hubspot/get-ids/`;
export const SEARCH_HUBSPOT_COMPANIES = (search: string) =>
  `/api/hubspot/search-hubspot-companies/?search=${search}`;

export const COMPANY_STATUSES = (companyId: number) =>
  `/api/companies/${companyId}/company-statuses/`;

export const COMPANY_FINANCIERS = (companyId: number) =>
  `/api/companies/${companyId}/financiers/`;

export const UPDATE_COMPANY_MAIN_CONTACT = (companyId: number) =>
  `/api/companies/${companyId}/update-main-contact/`;

export const DELETE_COMPANY_USER = `/auth/user/delete/`;

export const GET_ALL_CREDITS = (
  companyId: number,
  limit: number,
  page: number
) => `/api/credit/?company_id=${companyId}&limit=${limit}&page=${page}`;

export const GET_AVAILABLE_CREDITS_AMOUNT = (companyId: number) =>
  `/api/credit/available/?company_id=${companyId}`;

export const CREATE_CORE_COMPANY = (country: string, org_no: string) =>
  `/api/company/${country}/${org_no}/createCompanyCore`;

export const DOES_COMPANY_EXIST = `/api/companies/company_exists/`;

export const UPDATE_COMPANY_SEGMENT = (companyId: number) =>
  `/api/companies/${companyId}/update-segment/`;
export const UPDATE_COMPANY_HUBSPOT_ID = (companyId: number) =>
  `/api/companies/${companyId}/update-hubspot-vid/`;

export const SUPPLIER_REVIEW = (supplierId: number) =>
  `/api/supplier/${supplierId}/review/`;

export const BANK_ACCOUNT_REVIEW = (bankAccountId: number) =>
  `/api/bank_account/${bankAccountId}/review/`;

export const SIMILAR_BANK_ACCOUNTS = (bankAccountId: number) =>
  `/api/bank_account/${bankAccountId}/similar/`;

export const SUPPLIER_MONITORING = (supplierId: number) =>
  `/api/supplier/${supplierId}/monitoring/`;

export const ACTIVE_ORDER_REVIEW = (orderId: number) =>
  `/api/order/${orderId}/get-active-review/`;

export const NOTIFY_NORDEA_TAG = (orderId: number) =>
  `/api/order/${orderId}/notify-nordea-tag/`;
