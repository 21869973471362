export const BANK_ACCOUNT_TYPE = {
  regular: 'Local',
  priority: 'SWIFT',
  bankgiro: 'Bankgiro',
  plusgiro: 'Plusgiro',
};
export const REGULAR_PAYMENT = { value: 'regular', label: 'Local' };
export const PRIORITY_PAYMENT = { value: 'priority', label: 'SWIFT' };
export const BANKGIRO_PAYMENT = { value: 'bankgiro', label: 'Bankgiro' };
export const PLUSGIRO_PAYMENT = { value: 'plusgiro', label: 'Plusgiro' };

export const PAYMENT_TYPES = [
  REGULAR_PAYMENT,
  PRIORITY_PAYMENT,
  BANKGIRO_PAYMENT,
  PLUSGIRO_PAYMENT,
];
export const DEFAULT_COUNTRY = 'cn';
export const DEFAULT_CURRENCY = 'usd';
