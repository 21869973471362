import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import {
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  TRANSACTION_TYPE,
  transactionsTypesDisplayedValue,
} from '@/constants';
import { useGetTransactions } from '@/hooks';
import { Transaction } from '@/schemas';
import { Stack } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toString } from 'lodash';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

export const TransactionsTable = ({ filters }: { filters?: Filter[] }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'payment_date', sort: 'desc' },
  ]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const columns: GridColDef<Transaction>[] = useMemo(
    () => [
      {
        field: 'invoice__invoice_number',
        headerName: 'Invoice #',
        flex: 0,
        minWidth: 90,
        renderCell: (params) => {
          return (
            <Link to={`${ROUTES.invoices}/${params.row?.invoices?.[0]?.id}`}>
              {params.row?.invoices?.[0]?.invoice_number}
            </Link>
          );
        },
      },
      {
        field: 'invoice__order_id',
        headerName: 'Order #',
        flex: 0,
        minWidth: 90,
        renderCell: (params) => {
          const orderId = params.row?.invoices?.[0]?.order_id;
          return <Link to={`${ROUTES.orders}/${orderId}`}>{orderId}</Link>;
        },
      },
      {
        field: 'payment_date',
        headerName: 'Payment date',
        flex: 1,
        minWidth: 100,
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
      {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 150,
        flex: 1,
        cellClassName: 'tabular-numbers',
        renderCell: (params) =>
          formatCurrency(params.value, toString(params.row?.amount_currency)),
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 0,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150,
        renderCell: (params) =>
          transactionsTypesDisplayedValue[params.value as TRANSACTION_TYPE],
      },
    ],
    []
  );

  const { data, loading: isLoadingTransactions } = useGetTransactions({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: pageSize,
      offset: page * pageSize,
    },
  });

  const transactions = data?.invoicing_app_transaction || [];
  const rowCount =
    data?.invoicing_app_transaction_aggregate?.aggregate?.count || 0;

  const noTransactionsProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack height={400} width="100%">
      <DataGrid
        rows={transactions}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCount}
        pageSize={pageSize}
        page={page}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        sortModel={sortModel}
        loading={isLoadingTransactions}
        onSortModelChange={setSortModel}
        components={{
          NoRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'Nothing here yet'}
              subtitle={'Transactions will be displayed here'}
            />
          ),
          ...noTransactionsProps,
        }}
        {...noTransactionsProps}
      />
    </Stack>
  );
};
