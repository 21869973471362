import { Orders_Bank_Accounts } from '@/__generated__/graphql';
import { getCountryName } from '@treyd-io/core/utils/country';
import {
  pickBy,
  reduce,
  startCase,
  toLower,
  toString,
  upperCase,
} from 'lodash';

const LABELS: Record<string, string> = {
  bank_account_holder_name: 'Beneficiary name',
  bank_country: 'Bank country',
  bic_swift: 'BIC/SWIFT',
  account_number: 'Account number',
  clearing_number: 'Clearing no.',
  bank_code: 'Bank code',
  cnaps_code: 'CNAPS code',
  routing_code: 'Routing code',
  acct_number: 'Account number',
  aba_code: 'ABA',
  clabe_code: 'CLABE code',
  bsb_code: 'BSB code',
  plusgiro_number: 'PlusGiro code',
  bankgiro_number: 'BankGiro number',
  institution_number: 'Institution no.',
  ifsc_code: 'IFSC code',
  currency: 'Currency',
  bankgiro: 'Bankgiro',
  swift: 'SWIFT',
  iban: 'IBAN',
};

const createBankInfoMapper = (account: Partial<Orders_Bank_Accounts>) => {
  const excludedKeys = [
    '__typename',
    'id',
    'combination_id',
    'is_valid',
    'created_at',
    'payment_type',
    'error_msgs',
    'type',
    'updated_at',
    'remittance_text',
    'compliance',
  ];

  if (toLower(account.currency) !== 'sek')
    excludedKeys.push(...['bankgiro', 'plusgiro']);

  const viewableKeys = pickBy(account, (_, key) => {
    return !excludedKeys.includes(key);
  });

  const bankInfoMapper = reduce(
    viewableKeys,
    (acc: Record<string, string>, _, key) => {
      const label = LABELS[key] || startCase(key);
      acc[key] = label;
      return acc;
    },
    {}
  );

  return bankInfoMapper;
};

export const getBankInfo = <T extends Record<string, any>>(
  BankAccountInfo: T
) => {
  const bankInfoMapper = createBankInfoMapper(BankAccountInfo);
  const bankInfo = Object.entries(bankInfoMapper).map(([key, label]) => {
    if (key === 'currency')
      return {
        label,
        value: upperCase(BankAccountInfo[key]),
      };

    if (key.includes('country'))
      return {
        label,
        value: getCountryName(toString(BankAccountInfo[key])),
      };

    return {
      label,
      value: BankAccountInfo[key],
    };
  });

  return bankInfo;
};
