import { Box, Typography } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchField from '@/components/SearchField';
import { DEFAULT_GRID_COL_DEF, DEV_PATHS } from '@/constants';

import { CompanyUserType } from '@/schemas';

interface UsersTableProps {
  users: CompanyUserType[];
  companyID?: number | string;
}

//TODO: pass columns as props
const columns = [
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'id',
    headerName: 'User #',
    width: 100,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'member_id',
    headerName: 'Member ID',
    type: 'number',
    width: 100,
    renderCell: (params: any) => (params.value ? params.value : 'N/A'),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'name',
    headerName: 'Name',
    type: 'string',
    width: 200,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'first_name',
    headerName: 'First Name',
    type: 'string',
    width: 180,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'last_name',
    headerName: 'Last Name',
    type: 'string',
    width: 180,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'email',
    headerName: 'Email Address',
    type: 'email',
    width: 250,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'identity_verified',
    headerName: 'Identity Verified',
    type: 'string',
    width: 150,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'can_sign_alone',
    headerName: 'Can Sign Alone',
    type: 'string',
    width: 150,
    renderCell: (params: any) => (
      <div>{params.row.can_sign_alone ? 'Yes' : 'No'}</div>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'is_ubo',
    headerName: 'Is UBO',
    type: 'string',
    width: 150,
  },
];
export const UsersTable = (props: UsersTableProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const navigate = useNavigate();

  const getFilteredUsers = useCallback(() => {
    if (searchTerm === '') return props.users;
    return props.users.filter(
      (user) =>
        user?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        user.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }, [props.users, searchTerm]);

  const handleRowClick = useCallback(
    (params: any) => {
      if (!props.companyID) return;
      navigate(
        DEV_PATHS.user_details
          .replaceAll(':user_id', params.id)
          .replaceAll(':company_id', props.companyID.toString()),
        { state: { user: params.row } }
      );
    },
    [navigate, props.companyID]
  );

  return (
    <Box sx={(theme) => ({ marginBlockEnd: theme.spacing(2.5) })}>
      <Typography
        sx={(theme) => ({ marginBlockEnd: theme.spacing(1) })}
        variant="h5">
        Users
      </Typography>
      <Box
        sx={{ width: '33%', marginInlineEnd: '1.5rem', marginBlock: '1rem' }}>
        <SearchField
          placeholder="search for users by email, name, ..."
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </Box>
      <DataGrid
        rows={getFilteredUsers()}
        columns={columns}
        pageSize={5}
        autoHeight={true}
        onRowClick={handleRowClick}
        rowsPerPageOptions={[5]}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within, & .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            { outline: 'none' },
        }}
      />
    </Box>
  );
};
