import { MerchantWithLink } from '@/components';
import GenericTableView from '@/components/GenericTableView';
import { DEFAULT_GRID_COL_DEF, ORDER_REVIEW_PATHS } from '@/constants';
import OcrStatusIcon from '@/pages/orderReview/OcrStatusIcon';
import {
  Order,
  getAwaitingApprovalOrdersInOrderReviewQuery,
} from '@/schemas/getAwaitingApprovalOrdersInOrderReviewQuery';
import { getMappedOcrFields } from '@/utils';
import { Typography } from '@mui/material';
import { GridColDef, gridClasses } from '@mui/x-data-grid';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import AlertIcon from '@treyd-io/treyd-ui/icons/AlertIcon';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import { isEmpty, toNumber, toString } from 'lodash';
import { Link } from 'react-router-dom';

const columns: GridColDef<Order>[] = [
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'id',
    headerName: 'Order #',
    width: 150,
    renderCell: (params) => (
      <Link to={ORDER_REVIEW_PATHS[':id'].replaceAll(':id', params.value)}>
        {params.value}
      </Link>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'created',
    headerName: 'Created',
    type: 'date',
    width: 150,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'importer__name',
    headerName: 'Merchant',
    type: 'string',
    minWidth: 250,
    renderCell: (params) => {
      const id = params.row?.importer?.id;
      const name = params.row?.importer?.name;
      const segment = params.row?.importer?.segment;
      return name && <MerchantWithLink id={id} name={name} segment={segment} />;
    },
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'order_invoice__ocr_data',
    headerName: 'Checklist',
    type: 'string',
    sortable: false,
    width: 150,
    renderCell: (params) => {
      const ocrData = params.row?.order_invoice?.ocr_data;
      const data = getMappedOcrFields(ocrData);
      return <OcrStatusIcon ocrData={data} />;
    },
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'order_invoice__ops_approval_recommendation__is_auto_approval',
    headerName: 'Supplier invoice',
    type: 'string',
    width: 150,
    renderCell: (params) =>
      params.row?.order_invoice?.ops_approval_recommendation
        ?.is_auto_approval ? (
        <CheckCircleIcon color="success" />
      ) : (
        <AlertIcon color="error" />
      ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'compliance_approval_recommendation__is_auto_approval',
    headerName: 'Compliance',
    type: 'string',
    width: 150,
    renderCell: (params) =>
      params.row?.compliance_approval_recommendation?.is_auto_approval ? (
        <CheckCircleIcon color="success" />
      ) : (
        <AlertIcon color="error" />
      ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'financier__name',
    headerName: 'Financier',
    type: 'string',
    width: 250,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'active_payout__expected_payment_date',
    headerName: 'Expected payment date',
    type: 'date',
    width: 150,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'active_payout__amount',
    headerName: 'Payout amount',
    type: 'number',
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    minWidth: 250,
    renderCell: (params) => (
      <Typography variant="body2" sx={{ fontVariantNumeric: 'tabular-nums' }}>
        {params.value}
      </Typography>
    ),
  },
];

const formatDeal = (order: Order) => ({
  ...order,
  importer__name: order.importer?.name,
  created: order.created && formatDate(new Date(order.created), 'date'),
  financier__name: order.financier?.name,
  active_payout__expected_payment_date:
    order.active_payout?.expected_payment_date &&
    formatDate(new Date(order.active_payout?.expected_payment_date), 'date'),
  active_payout__amount: formatCurrency(
    toNumber(order.active_payout?.amount),
    toString(order.active_payout?.currency)
  ),
  order_invoice__ocr_data: order.order_invoice?.ocr_data,
});

export const NewOrdersTable = () => {
  return (
    <GenericTableView
      searchPlaceholder="Search for order #, merchants, financiers"
      columns={columns}
      getGQLSchema={getAwaitingApprovalOrdersInOrderReviewQuery}
      rowFormatter={formatDeal}
      resourceName="orders_orders"
      disableSelectionOnClick
      getRowClassName={({ row }: { row: Order }) =>
        isEmpty(row.importer.first_paid_order) ? 'firstOrder' : ''
      }
      sx={{
        [`& .${gridClasses.row}.firstOrder`]: {
          backgroundColor: 'info.190p',
        },
      }}
    />
  );
};
