import { useAuth } from '@/auth';
import { AppNavBarContext } from '@/context';
import { Stack, useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopNavBar } from './DesktopNavBar';
import { MobileNavBar } from './MobileNavBar';

interface AppNavBarProps {
  children: ReactNode;
}

export const AppNavBar = (props: AppNavBarProps) => {
  const { children } = props;
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuth();
  const pathName = useLocation();
  const selectedRouteSegment = pathName.pathname.split('/')[1];
  const { isAppNavBarHidden } = useContext(AppNavBarContext);

  if (isAppNavBarHidden) {
    return (
      <Stack component="main" width={'100%'} minHeight="100vh" flexGrow={1}>
        {children}
      </Stack>
    );
  }
  return (
    <Stack direction={{ xs: 'column', md: 'row' }}>
      {isSmallScreens ? (
        <MobileNavBar user={user} selectedRouteSegment={selectedRouteSegment} />
      ) : (
        <DesktopNavBar
          user={user}
          selectedRouteSegment={selectedRouteSegment}
        />
      )}

      <Stack
        component="main"
        width={'100%'}
        margin={'auto'}
        minHeight="100vh"
        padding={isSmallScreens ? 2 : 6}
        paddingY={isSmallScreens ? 2 : 5}
        marginTop={isSmallScreens ? 10 : 0}
        flexGrow={1}>
        {children}
      </Stack>
    </Stack>
  );
};
