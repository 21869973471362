import { MAPPED_ORDER_STATUS, MERCHANT_SEGMENT } from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { GridSortItem } from '@mui/x-data-grid';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Orders as Orders,
  Orders_Companies,
  Orders_Order_Payouts,
  Orders_Orders_Aggregate,
} from '__generated__/graphql';
import { isEmpty, toNumber } from 'lodash';

export type Order = Pick<
  Orders,
  'id' | 'created' | 'modified' | 'mapped_status'
> & {
  importer: Pick<Orders_Companies, 'id' | 'name' | 'country'> & {
    segment: MERCHANT_SEGMENT;
  };
  active_payout: Pick<
    Orders_Order_Payouts,
    'expected_payment_date' | 'amount' | 'currency'
  >;
};

type GetDraftDealsQuery = {
  orders_orders: Array<Order>;
  orders_orders_aggregate: Pick<Orders_Orders_Aggregate, 'aggregate'>;
};
export const getDraftOrdersInOrderReviewQuery = (
  offset: number,
  limit: number,
  searchTerm = '',
  sorting = {},
  filters: { mapped_status?: MAPPED_ORDER_STATUS[] } = {}
) => {
  const newOrdersFilters: Filter[] = [
    {
      type: 'search',
      name: 'id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(searchTerm)
          ? null
          : toNumber(searchTerm),
    },
    {
      type: 'search',
      name: 'importer__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'financier__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'filter',
      name: 'mapped_status',
      comparator: FilterComparator.IN,
      comparisonValue: [MAPPED_ORDER_STATUS.DRAFT, MAPPED_ORDER_STATUS.PREVIEW],
    },
    {
      type: 'filter',
      name: 'mapped_status',
      comparator: FilterComparator.IN,
      comparisonValue: filters?.mapped_status,
    },
  ];

  const { queryParams, whereClause } = getGqlQueryParams({
    limit,
    offset,
    sorting: sorting as GridSortItem,
    filters: newOrdersFilters,
  });

  return gql`
  query DraftDeals {
      orders_orders ${queryParams} {
				id
        mapped_status
        active_payout {
          amount
          currency
          expected_payment_date
        }
				created
        modified
        importer {
          id
          name
          country
					segment
        }
      }
  
      orders_orders_aggregate (where: ${whereClause}) {
        aggregate {
          count
        }
      }
    }
  ` as TypedDocumentNode<GetDraftDealsQuery, {}>;
};
