import { SearchField } from '@/components';
import { ExportSuppliersButton } from '@/components/Suppliers/ExportSuppliersButton';
import { useGetTableSettings } from '@/hooks';
import useSearchTerm from '@/hooks/useSearchTerm';
import { SuppliersTableFiltersFields } from '@/types';
import { Stack, Typography } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { toString } from 'lodash';
import { SuppliersTable } from './SuppliersTable';
import { TableFilters } from './TableFilters';

export const SuppliersPage = () => {
  const { searchTerm, setSearchTerm } = useSearchTerm('suppliersSearchTerm');

  const {
    suppliersTableSettings: { filterFields, setFilterFields, resetTable },
  } = useGetTableSettings();

  const searchFields: QuerySearchField<SuppliersTableFiltersFields>[] = [
    {
      type: 'search',
      name: 'name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
    {
      type: 'search',
      name: 'legal_name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
    {
      type: 'search',
      name: 'company__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
  ];

  const filters: Filter<SuppliersTableFiltersFields>[] = [
    ...searchFields,
    ...(filterFields || []),
  ];

  return (
    <Stack flexGrow={1} gap={5} height="100%">
      <Stack flexGrow={1} gap={3} height="100%">
        <Row>
          <Col xs="auto">
            <Typography variant="h4">Suppliers</Typography>
          </Col>
          <Col xs={12} sm>
            <SearchField
              placeholder={
                'Search by supplier name, legal name, or merchant name'
              }
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Col>
          <Col xs={12} sm="auto">
            <TableFilters
              onSubmit={setFilterFields}
              onReset={resetTable}
              filters={filterFields}
            />
          </Col>
          <Col xs={12} sm="auto">
            <ExportSuppliersButton filters={filters} />
          </Col>
        </Row>
        <SuppliersTable filters={filters} />
      </Stack>
    </Stack>
  );
};
