import { values } from 'lodash';

export const treydInvoicingSystem = 'Treyd';

export enum INVOICE_DETAILS_TABS {
  DETAILS,
  PREVIEW,
  TRANSACTIONS,
}

export enum ComputedInvoiceStatus {
  PARTIAL = 'partial',
  PAID = 'paid',
  NO_INVOICE = 'no_invoice',
}
interface AlertObject {
  title: string;
  subtitle?: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  variant?: 'filled' | 'standard' | 'outlined';
}

export type InvoiceStatusMapper = Partial<
  Record<ComputedInvoiceStatus, AlertObject | null>
>;

export enum INVOICE_OPS_STATUS {
  DRAFT = 'draft',
  ATTESTED = 'attested',
  SALES_REQUESTED = 'sales_requested',
  SOLD = 'sold',
  SALES_REQUEST_DENIED = 'sales_request_denied',
  SALES_REQUEST_CANCELLED = 'sales_request_cancelled',
  OPEN = 'open',
  OVERDUE = 'overdue',
  MATURE_FOR_COLLECTION = 'mature_for_collection',
  COLLECTION = 'collection',
  WRITTEN_OFF = 'written_off',
  CLOSED = 'closed',
}

export const invoiceStatuses = values(INVOICE_OPS_STATUS);

export const invoiceStatusesDisplayedValue = {
  [INVOICE_OPS_STATUS.DRAFT]: 'Draft',
  [INVOICE_OPS_STATUS.ATTESTED]: 'Attested',
  [INVOICE_OPS_STATUS.SALES_REQUESTED]: 'Sales requested',
  [INVOICE_OPS_STATUS.SOLD]: 'Sold',
  [INVOICE_OPS_STATUS.SALES_REQUEST_DENIED]: 'Sales request denied',
  [INVOICE_OPS_STATUS.SALES_REQUEST_CANCELLED]: 'Sales request cancelled',
  [INVOICE_OPS_STATUS.OPEN]: 'Open',
  [INVOICE_OPS_STATUS.OVERDUE]: 'Overdue',
  [INVOICE_OPS_STATUS.MATURE_FOR_COLLECTION]: 'Mature for collection',
  [INVOICE_OPS_STATUS.COLLECTION]: 'Collection',
  [INVOICE_OPS_STATUS.WRITTEN_OFF]: 'Written off',
  [INVOICE_OPS_STATUS.CLOSED]: 'Closed',
};

export enum INVOICE_TYPE {
  ACQUIRING = 'acquiring',
  DEBTOR = 'debtor',
  SKG = 'skg',
  SELF_INVOICE = 'sfi',
  CREDIT_SELF_INVOICE = 'credit_sfi',
  CREDIT = 'credit',
  INTEREST = 'interest',
  EXTRA = 'extra',
}
export const invoiceTypes = values(INVOICE_TYPE);

export const invoiceTypesDisplayedValue = {
  [INVOICE_TYPE.ACQUIRING]: 'Acquiring',
  [INVOICE_TYPE.DEBTOR]: 'Debtor',
  [INVOICE_TYPE.SKG]: 'SKG',
  [INVOICE_TYPE.SELF_INVOICE]: 'SFI',
  [INVOICE_TYPE.CREDIT_SELF_INVOICE]: 'Credit SFI',
  [INVOICE_TYPE.CREDIT]: 'Credit',
  [INVOICE_TYPE.INTEREST]: 'Interest',
  [INVOICE_TYPE.EXTRA]: 'Extra',
};

export const invoiceSentOpsStatuses: INVOICE_OPS_STATUS[] = [
  INVOICE_OPS_STATUS.OPEN,
  INVOICE_OPS_STATUS.OVERDUE,
  INVOICE_OPS_STATUS.MATURE_FOR_COLLECTION,
  INVOICE_OPS_STATUS.COLLECTION,
  INVOICE_OPS_STATUS.CLOSED,
  INVOICE_OPS_STATUS.WRITTEN_OFF,
];

export const invoicePassedDueDateOpsStatuses: INVOICE_OPS_STATUS[] = [
  INVOICE_OPS_STATUS.OVERDUE,
  INVOICE_OPS_STATUS.MATURE_FOR_COLLECTION,
  INVOICE_OPS_STATUS.COLLECTION,
  INVOICE_OPS_STATUS.WRITTEN_OFF,
];

export const invoiceClosedAndPassedDueDateOpsStatuses = [
  INVOICE_OPS_STATUS.CLOSED,
  INVOICE_OPS_STATUS.SALES_REQUEST_DENIED,
  INVOICE_OPS_STATUS.SALES_REQUEST_CANCELLED,
];
