import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { COUNTRIES_WITH_STATES_OR_PROVINCES } from '@/constants';
import { useGetSupplierKyc, useUpdateSupplierData } from '@/hooks';
import { Supplier } from '@/schemas';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { COUNTRIES_OPTIONS } from '@treyd-io/core/constants/country';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { useFormik } from 'formik';
import { toString } from 'lodash';
import * as Yup from 'yup';

export const EditSupplierInfo = ({
  supplier,
  isOpen,
  onClose,
}: {
  supplier: Supplier;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [updateSupplierData] = useUpdateSupplierData(supplier?.id);
  const showNotificationMessage = useToastNotification();
  const { data } = useGetSupplierKyc(supplier?.id);

  const isComplianceDescisionMade =
    data?.orders_suppliers_by_pk?.kyc.status === 'Preview';

  const formik = useFormik({
    initialValues: {
      legal_name: toString(supplier?.legal_name),
      full_address: toString(supplier?.full_address),
      city: toString(supplier?.city),
      country: toString(supplier?.country),
      zip_code: toString(supplier?.zip_code),
      org_no: toString(supplier?.org_no),
      website: toString(supplier?.website),
      state_or_province: toString(supplier?.state_or_province),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      full_address: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      zip_code: Yup.string().required('Required'),
      website: Yup.string().optional(),
      state_or_province: Yup.string().when('country', ([country], schema) => {
        return COUNTRIES_WITH_STATES_OR_PROVINCES.includes(
          country?.toLowerCase()
        )
          ? schema.required('Required')
          : schema.notRequired();
      }),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      updateSupplierData({
        variables: {
          id: supplier?.id,
          supplier_data: values,
        },
      })
        .then(() => {
          showNotificationMessage({
            title: 'Supplier info updated',
            type: 'success',
          });
          onClose();
        })
        .catch(() => {
          showNotificationMessage({
            title: 'Something went wrong',
            type: 'error',
          });
        });
    },
  });

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          borderRadius: 3,
          paddingBlockEnd: 1.5,
        },
      }}
      fullWidth>
      <DialogTitle>Edit supplier info</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),
          })}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Legal name (optional)"
                name="legal_name"
                value={formik.values.legal_name}
                onChange={formik.handleChange}
                error={formik.touched.legal_name && !!formik.errors.legal_name}
                helperText={
                  formik.touched.legal_name && formik.errors.legal_name
                }
                placeholder="Calle Cencibel"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Full address"
                name="full_address"
                value={formik.values.full_address}
                disabled={isComplianceDescisionMade}
                onChange={formik.handleChange}
                error={
                  formik.touched.full_address && !!formik.errors.full_address
                }
                helperText={
                  formik.touched.full_address && formik.errors.full_address
                }
                placeholder="Calle Cencibel (Pol Ind Alces (Antes C/Mencia)) 6"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && !!formik.errors.city}
                helperText={formik.touched.city && formik.errors.city}
                placeholder="Madrid"
              />
            </Col>
            <Col xs={12} sm={6} md={6}>
              <TextField
                fullWidth
                label="Zip code"
                name="zip_code"
                value={formik.values.zip_code}
                onChange={formik.handleChange}
                error={formik.touched.zip_code && !!formik.errors.zip_code}
                helperText={formik.touched.zip_code && formik.errors.zip_code}
                placeholder="28037"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <Autocomplete
                options={COUNTRIES_OPTIONS}
                value={
                  COUNTRIES_OPTIONS.find(
                    (item) => item.value === formik.values.country
                  ) || null
                }
                fullWidth
                getOptionLabel={(option) => option.title || ''}
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                onChange={(e, item) => {
                  formik.setFieldValue(
                    'country',
                    item && item.value ? item.value : ''
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={'Country'}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6} md={6}>
              <TextField
                fullWidth
                label="Organization number"
                name="org_no"
                value={formik.values.org_no}
                onChange={formik.handleChange}
                error={formik.touched.org_no && !!formik.errors.org_no}
                helperText={formik.touched.org_no && formik.errors.org_no}
                placeholder="B-123456"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <TextField
                fullWidth
                label={'State or province'}
                name="state_or_province"
                value={formik.values.state_or_province}
                onChange={formik.handleChange}
                error={
                  formik.touched.state_or_province &&
                  !!formik.errors.state_or_province
                }
                helperText={
                  formik.touched.state_or_province &&
                  formik.errors.state_or_province
                }
              />
            </Col>
            <Col xs={12} sm={6} md={6}>
              <TextField
                fullWidth
                label="Website (optional)"
                name="website"
                value={formik.values.website}
                onChange={formik.handleChange}
                error={formik.touched.website && !!formik.errors.website}
                helperText={formik.touched.website && formik.errors.website}
                placeholder="www.example.com"
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose()}
            color="secondary"
            variant="outlined"
            size="large">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={!formik.dirty}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
