import { MerchantWithLink, OrderStatusChip } from '@/components';
import GenericTableView from '@/components/GenericTableView';
import {
  DEFAULT_GRID_COL_DEF,
  MAPPED_ORDER_STATUS,
  ORDER_MAPPED_STATUS_DISPLAYED_VALUE,
  ORDER_REVIEW_PATHS,
} from '@/constants';
import {
  Order,
  getCancelledOrdersInOrderReviewQuery,
} from '@/schemas/getCancelledOrdersInOrderReviewQuery';
import { Stack, Tooltip, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { formatDate, newISODate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import AlertIcon from '@treyd-io/treyd-ui/icons/AlertIcon';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import { toNumber, toString } from 'lodash';
import { Link } from 'react-router-dom';

const statusCheckMark = {
  success: <CheckCircleIcon color="success" />,
  error: <AlertIcon color="error" />,
};

const columns: GridColumns<Order> = [
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'id',
    headerName: 'Order #',
    width: 150,
    renderCell: (params) => (
      <Link to={ORDER_REVIEW_PATHS[':id'].replaceAll(':id', params.value)}>
        {params.value}
      </Link>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'mapped_status',
    headerName: 'Status',
    type: 'string',
    renderCell: (params) => (
      <OrderStatusChip size="small" status={params.value} />
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'importer__name',
    headerName: 'Merchant',
    type: 'string',
    width: 200,
    renderCell: (params) => {
      const id = params.row?.importer?.id;
      const name = params.row?.importer?.name;
      const segment = params.row?.importer?.segment;
      return name && <MerchantWithLink id={id} name={name} segment={segment} />;
    },
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'financier__name',
    headerName: 'Financier',
    type: 'string',
    width: 200,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'modified',
    headerName: 'Rejected',
    type: 'dateTime',
    sortable: true,
    width: 250,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'order_invoice__ocr_data',
    headerName: 'Auto approval',
    type: 'string',
    sortable: false,
    width: 150,
    renderCell: (params) => {
      const opsAutoApprovalStatus = params.row?.order_invoice
        ?.ops_approval_recommendation?.is_auto_approval
        ? 'success'
        : 'error';
      const complianceAutoApprovalStatus = params.row
        ?.compliance_approval_recommendation?.is_auto_approval
        ? 'success'
        : 'error';
      return (
        <Stack direction="row" gap={1}>
          <Tooltip title="Supplier invoice" arrow>
            <Stack>{statusCheckMark[opsAutoApprovalStatus]}</Stack>
          </Tooltip>
          <Tooltip title="Compliance" arrow>
            <Stack>{statusCheckMark[complianceAutoApprovalStatus]}</Stack>
          </Tooltip>
        </Stack>
      );
    },
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'active_payout__expected_payment_date',
    headerName: 'Expected payment date',
    type: 'date',
    width: 150,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'active_payout__amount',
    headerName: 'Payout amount',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    sortable: false,
    width: 200,
    renderCell: (params) => (
      <Typography variant="body2" sx={{ fontVariantNumeric: 'tabular-nums' }}>
        {params.value}
      </Typography>
    ),
  },
];

const formatRow = (order: Order) => ({
  ...order,
  importer__name: order.importer?.name,
  financier__name: order.financier?.name,
  active_payout__expected_payment_date:
    order.active_payout?.expected_payment_date &&
    formatDate(new Date(order.active_payout?.expected_payment_date), 'date'),
  active_payout__amount: formatCurrency(
    toNumber(order.active_payout?.amount),
    toString(order.active_payout?.currency)
  ),
  modified: newISODate(order.modified),
});

export const CancelledOrdersTable = () => {
  return (
    <GenericTableView
      searchPlaceholder="Search for order #, merchants, financiers"
      getGQLSchema={getCancelledOrdersInOrderReviewQuery}
      columns={columns}
      rowFormatter={formatRow}
      resourceName="orders_orders"
      disableSelectionOnClick
      filterList={[
        {
          field: 'mapped_status',
          options: [MAPPED_ORDER_STATUS.REJECTED, MAPPED_ORDER_STATUS.CANCELED],
          label: 'Status',
        },
      ]}
      filterMapper={(status) =>
        ORDER_MAPPED_STATUS_DISPLAYED_VALUE[status as MAPPED_ORDER_STATUS]
      }
    />
  );
};
