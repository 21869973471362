import { ORDER_EVENT } from '@/constants';
import { useSubmitOrderEvent } from '@/hooks';
import { Button, Stack } from '@mui/material';

const ApprovedOrderActionButtons = ({
  orderId,
  goBack,
}: {
  orderId: number;
  goBack: () => void;
}) => {
  const { mutate: submitOrderEvent, isPending } = useSubmitOrderEvent();

  const handleSendBack = () => {
    submitOrderEvent(
      {
        id: orderId,
        eventName: ORDER_EVENT.SEND_BACK,
      },
      {
        onSuccess: goBack,
      }
    );
  };
  const handleCancel = () => {
    submitOrderEvent(
      {
        id: orderId,
        eventName: ORDER_EVENT.CANCEL,
      },
      {
        onSuccess: goBack,
      }
    );
  };

  return (
    <Stack alignItems="center" direction="row">
      <Button
        disabled={isPending}
        color="info"
        onClick={handleSendBack}
        size="large">
        Send back
      </Button>
      <Button
        disabled={isPending}
        color="error"
        onClick={handleCancel}
        size="large">
        Cancel
      </Button>
    </Stack>
  );
};

export default ApprovedOrderActionButtons;
