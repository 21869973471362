import { Dialog, DialogContent, Typography } from '@mui/material';

import BankAccountForm from '@/pages/suppliers/SupplierDetails/Tabs/BankInfoTab/BankAccountForm';

interface BankAccountDialogProps {
  open: boolean;
  onClose: () => void;
  entity: 'supplier' | 'company';
  entityId: string;
  entityCountry: string;
  onSuccess: () => void;
}
const BankAccountDialog = (props: BankAccountDialogProps) => {
  const { open, onClose, entity, entityCountry, entityId, onSuccess } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          width: '444px',
        }}>
        <Typography variant="h5">Add bank account</Typography>
        <BankAccountForm
          onClose={onClose}
          entity={entity}
          entityId={entityId}
          entityCountry={entityCountry}
          onSuccess={onSuccess}
        />
      </DialogContent>
    </Dialog>
  );
};

export default BankAccountDialog;
