import { DEFAULT_GRID_COL_DEF, DEV_PATHS } from '@/constants';
import { useGetFeatureFlags, useInsertFeatureFlag } from '@/hooks';
import { InsertFeatureFlagDialog } from '@/pages/dev/components/InsertFeatureFlagDialog';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { DataGrid, GridRenderCellParams, gridClasses } from '@mui/x-data-grid';
import { startCase, toString } from 'lodash';
import { Link } from 'react-router-dom';
const columns = [
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'id',
    headerName: 'Id',
    width: 20,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        to={DEV_PATHS.feature_flag_details.replaceAll(
          ':feature_flag_id',
          toString(params.id)
        )}>
        {params.id}
      </Link>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'name',
    headerName: 'Name',
    type: 'string',
    width: 100,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'sanitizedName',
    headerName: 'Sanitized Name',
    type: 'string',
    width: 100,
    valueGetter: (params: GridRenderCellParams) => startCase(params.row.name),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'enabled',
    headerName: 'Enabled',
    type: 'boolean',
    width: 100,
    renderCell: (params: GridRenderCellParams) => (params.value ? 'Yes' : 'No'),
  },
];
export const FeatureFlags = () => {
  const {
    data: featureFlagsData,
    loading: featureFlagsLoading,
    refetch: refetchFeatureFlags,
  } = useGetFeatureFlags();

  const [insertFeatureFlag, { loading: insertFeatureFlagLoading }] =
    useInsertFeatureFlag();

  const featureFlags = featureFlagsData?.orders_orders_app_companyfeatureflag;

  const insertFeatureFlagHandler = async (name: string, enabled: boolean) => {
    await insertFeatureFlag({
      variables: {
        featureFlag: {
          name,
          enabled,
        },
      },
    });
    refetchFeatureFlags();
  };

  if (featureFlagsLoading || insertFeatureFlagLoading) {
    return (
      <Box
        width={'100%'}
        height="100%"
        display={'flex'}
        alignItems="center"
        justifyContent={'center'}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Stack flexGrow={1} height={'100%'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        marginBottom={2}>
        <Typography
          sx={(theme) => ({ marginBlockEnd: theme.spacing(1) })}
          variant="h5">
          Feature Flags
        </Typography>
        <InsertFeatureFlagDialog
          insertFeatureFlagHandler={insertFeatureFlagHandler}
        />
      </Box>
      <DataGrid
        rows={featureFlags || []}
        columns={columns}
        pageSize={10}
        autoHeight={true}
        rowsPerPageOptions={[10]}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within, & .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            { outline: 'none' },
        }}
      />
    </Stack>
  );
};
