import { gql, TypedDocumentNode } from '@apollo/client';

type BeneficiaryRequirementsQueryType = {
  banking: {
    beneficiary_requirements: Array<{
      [key: string]: string;
    }>;
  };
};

type BeneficiaryRequirementsQueryVariables = {
  currency: string;
  beneficiaryCountry: string;
  bankAccountCountry: string;
};

export const getBeneficiaryRequirementsQuery = gql`
  query getBeneficiaryRequirements(
    $currency: String!
    $beneficiaryCountry: String!
    $bankAccountCountry: String!
  ) {
    banking {
      beneficiary_requirements(
        currency: $currency
        beneficiary_country: $beneficiaryCountry
        bank_account_country: $bankAccountCountry
      )
    }
  }
` as TypedDocumentNode<
  BeneficiaryRequirementsQueryType,
  BeneficiaryRequirementsQueryVariables
>;
