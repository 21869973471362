import { MAPPED_ORDER_STATUS, MERCHANT_SEGMENT } from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { GridSortItem } from '@mui/x-data-grid';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Orders as Orders,
  Orders_Companies,
  Orders_Financiers,
  Orders_Order_Payouts,
  Orders_Orders_Aggregate,
  Orders_Orders_App_Aiapprovalrecommendation,
  Orders_Orders_App_Complianceapprovalrecommendation,
} from '__generated__/graphql';
import { isEmpty, toNumber } from 'lodash';

export type Order = Pick<Orders, 'id' | 'modified' | 'mapped_status'> & {
  importer: Pick<Orders_Companies, 'id' | 'name' | 'country'> & {
    segment: MERCHANT_SEGMENT;
  };
  financier: Pick<Orders_Financiers, 'id' | 'name'>;
  active_payout: Pick<
    Orders_Order_Payouts,
    'expected_payment_date' | 'currency' | 'amount'
  >;
  order_invoice: {
    ops_approval_recommendation: Pick<
      Orders_Orders_App_Aiapprovalrecommendation,
      'is_auto_approval'
    >;
  };
  compliance_approval_recommendation: Pick<
    Orders_Orders_App_Complianceapprovalrecommendation,
    'is_auto_approval'
  >;
};

type GetCancelledDealsQuery = {
  orders_orders: Array<Order>;
  orders_orders_aggregate: Pick<Orders_Orders_Aggregate, 'aggregate'>;
};

export const getCancelledOrdersInOrderReviewQuery = (
  offset: number,
  limit: number,
  searchTerm = '',
  sorting = {},
  filters: { mapped_status?: MAPPED_ORDER_STATUS[] } = {}
) => {
  const canceledOrdersFilters: Filter[] = [
    {
      type: 'search',
      name: 'id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(searchTerm)
          ? null
          : toNumber(searchTerm),
    },
    {
      type: 'search',
      name: 'importer__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'financier__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'filter',
      name: 'mapped_status',
      comparator: FilterComparator.IN,
      comparisonValue: filters?.mapped_status,
    },
    {
      type: 'filter',
      name: 'mapped_status',
      comparator: FilterComparator.IN,
      comparisonValue: [
        MAPPED_ORDER_STATUS.REJECTED,
        MAPPED_ORDER_STATUS.CANCELED,
      ],
    },
  ];

  const { queryParams, whereClause } = getGqlQueryParams({
    limit,
    offset,
    sorting: sorting as GridSortItem,
    filters: canceledOrdersFilters,
  });

  return gql`
  query CancelledDeals {
      orders_orders ${queryParams} {
				id
        modified
        mapped_status
        financier {
          id
          name
        }
        importer {
          id
          name
          country
					segment
        }
        active_payout {
          expected_payment_date
          amount
          currency
        }
        order_invoice{
          ops_approval_recommendation {
            is_auto_approval
          }
        }
        compliance_approval_recommendation{
          is_auto_approval
        }
      }
  
      orders_orders_aggregate (where: ${whereClause}) {
        aggregate {
          count
        }
      }
    }
  ` as TypedDocumentNode<GetCancelledDealsQuery, {}>;
};
