import { alertClasses } from '@mui/material/Alert';
import { backdropClasses } from '@mui/material/Backdrop';
import { buttonClasses } from '@mui/material/Button';
import { touchRippleClasses } from '@mui/material/ButtonBase';
import dialogActionsClasses from '@mui/material/DialogActions/dialogActionsClasses';
import { iconButtonClasses } from '@mui/material/IconButton';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { tabClasses } from '@mui/material/Tab';
import { typographyClasses } from '@mui/material/Typography';
import {
  Shadows,
  ThemeOptions,
  alpha,
  createTheme,
} from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import React from 'react';
import AlertIcon from '../icons/AlertIcon';
import CalendarIcon from '../icons/CalendarIcon';
import CheckCircleIcon from '../icons/CheckCircleIcon';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import InformationIcon from '../icons/InformationIcon';
import WarningTriangleIcon from '../icons/WarningTriangleIcon';

declare module '@mui/material/styles/createPalette' {
  interface OtherColors {
    divider: string;
    outlined_border: string;
    standard_input_line: string;
    backdrop_overlay: string;
    rating_active: string;
    snackbar: string;
  }
  interface Palette {
    other: OtherColors;
  }
  interface PaletteOptions {
    other?: Partial<OtherColors>;
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    standard: true;
  }
  interface ChipClasses {
    standardError: string;
    standardWarning: string;
    standardInfo: string;
    standardSuccess: string;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    inherit: false;
    default: false;
  }
}

type Color = 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

const mainThemeOptions: ThemeOptions = {
  spacing: (factor: number) => `${0.5 * factor}rem`,
  shadows: [
    ...createTheme({}).shadows.map((element, index) => {
      if (index === 6)
        return '0px 3px 5px -1px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(0, 0, 0, 0.06)';
      if (index === 12)
        return '0px 7px 8px -4px rgba(0, 0, 0, 0.12), 0px 12px 17px 2px rgba(0, 0, 0, 0.08), 0px 5px 22px 4px rgba(0, 0, 0, 0.08)';
      if (index === 24)
        return '0px 11px 15px -7px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.08), 0px 9px 46px 8px rgba(0, 0, 0, 0.08)';
      return element;
    }),
  ] as Shadows,
  typography: {
    h1: {
      fontWeight: 550,
      fontSize: '6rem',
      lineHeight: 1.167,
      letterSpacing: '-0.21875rem',
    },
    h2: {
      fontWeight: 550,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.09375rem',
    },
    h3: {
      fontWeight: 550,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '-0.075625rem',
    },
    h4: {
      fontWeight: 550,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '-0.01875rem',
    },
    h5: {
      fontWeight: 550,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '-0.0125rem',
    },
    h6: {
      fontWeight: 550,
      fontSize: '1.25rem',
      lineHeight: 1.25,
      letterSpacing: '0.009375rem',
    },
    body1: {
      fontWeight: 300,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.009375rem',
    },
    body2: {
      fontWeight: 300,
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: '0.010625rem',
    },
    caption: {
      fontWeight: 300,
      fontSize: '0.75rem',
      lineHeight: 1.5,
      letterSpacing: '0.025rem',
    },
    button: {
      fontWeight: 550,
      lineHeight: 1.5,
      textTransform: 'none',
    },
    subtitle1: {
      fontWeight: 550,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.009375rem',
    },
    subtitle2: {
      fontWeight: 550,
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: '0.00625rem',
    },
    overline: {
      fontWeight: 300,
      fontSize: '0.75rem',
      lineHeight: 1.5,
      letterSpacing: '0.0625rem',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        sizeSmall: ({ theme }) => ({
          paddingBlock: theme.spacing(1),
        }),
        root: ({ theme }) => ({
          borderRadius: theme.spacing(1),
          padding: theme.spacing(1.5),
          [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: theme.palette.text.primary,
              borderWidth: 1,
            },
          '&:hover': {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.grey[500],
            },
          },
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.other.outlined_border,
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: ({ theme }) => ({
          [`&[data-shrink=false]`]: {
            fontSize: theme.spacing(1.75),
          },
        }),
        outlined: ({ theme }) => ({
          fontWeight: '300',
          lineHeight: 1.71429,
          top: `-${theme.spacing(0.5)}`,
          [`&.${inputLabelClasses.focused}, &[data-shrink="true"], &.${inputLabelClasses.sizeSmall}`]:
            {
              top: `-${theme.spacing(0.1)}`,
            },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          [`& .${inputBaseClasses.root}`]: {
            [`& .${inputBaseClasses.input}`]: {
              padding: 0,
              minHeight: theme.spacing(3),
            },
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(1),
          [`&.${buttonClasses.disabled} .${buttonClasses.startIcon}, &.${buttonClasses.disabled} .${buttonClasses.endIcon}`]:
            {
              color: theme.palette.secondary.dark,
            },
        }),
        sizeSmall: ({ theme }) => ({
          paddingBlock: theme.spacing(0.625),
          paddingInline: theme.spacing(1.25),
          fontWeight: 550,
          fontSize: theme.spacing(1.5),
          lineHeight: 1.83333,
          letterSpacing: theme.spacing(0.0575),
        }),
        sizeMedium: ({ theme }) => ({
          paddingBlock: theme.spacing(1),
          paddingInline: theme.spacing(1.5),
          fontWeight: 550,
          fontSize: theme.spacing(1.75),
          lineHeight: 1.71429,
          letterSpacing: theme.spacing(0.05),
        }),
        sizeLarge: ({ theme }) => ({
          paddingBlock: theme.spacing(1.375),
          paddingInline: theme.spacing(2),
          fontWeight: 550,
          fontSize: theme.spacing(1.75),
          lineHeight: 1.85714,
          letterSpacing: theme.spacing(0.0575),
        }),
        outlinedSecondary: ({ theme }) => ({
          color: theme.palette.text.primary,
          borderColor: theme.palette.secondary.dark,
          '&:hover, &:focus': {
            borderColor: theme.palette.secondary.dark,
            backgroundColor: alpha(theme.palette.common.black, 0.04),
          },
        }),
        outlinedPrimary: ({ theme }) => ({
          '&:hover, &:focus': {
            backgroundColor: alpha(theme.palette.common.black, 0.04),
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          borderRadius: theme.spacing(1),
          [`& .${touchRippleClasses.root} .${touchRippleClasses.child}`]: {
            borderRadius: theme.spacing(1),
          },
          [`&.${iconButtonClasses.disabled}`]: {
            color: theme.palette.secondary.dark,
          },
          ...(ownerState.variant === 'outlined' && {
            border: 1,
            borderStyle: 'solid',
          }),
          ...(ownerState.variant === 'contained' && {
            backgroundColor: theme.palette[ownerState.color as Color]?.main,
            color: theme.palette[ownerState.color as Color]?.contrastText,
            '&:hover, &:focus': {
              backgroundColor: theme.palette[ownerState.color as Color]?.dark,
            },
          }),
        }),
        colorSecondary: ({ theme }) => ({
          color: theme.palette.text.primary,
          borderColor: theme.palette.secondary.dark,
          '&:hover, &:focus': {
            borderColor: theme.palette.secondary.dark,
            backgroundColor: alpha(theme.palette.common.black, 0.04),
          },
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'info.main',
        variant: 'body1',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(2),
          [`&:not(.${linearProgressClasses.buffer})`]: {
            backgroundColor: theme.palette.grey[100],
          },
          height: theme.spacing(1),
        }),
        barColorPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.info.main,
        }),
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          strokeLinecap: 'round',
        },
        colorPrimary: ({ theme }) => ({
          color: theme.palette.info.main,
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`& .${typographyClasses.root}`]: {
            ...theme.typography.body2,
            color: theme.palette.text.disabled,
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...theme.typography.body1,
          minHeight: 'auto',
          [`& .${tabClasses.iconWrapper}`]: {
            margin: theme.spacing(
              ownerState.iconPosition === 'bottom' ? 0.75 : 0,
              ownerState.iconPosition === 'start' ? 0.75 : 0,
              ownerState.iconPosition === 'top' ? 0.75 : 0,
              ownerState.iconPosition === 'end' ? 0.75 : 0
            ),
          },
          [`&.${tabClasses.selected}`]: {
            ...theme.typography.subtitle1,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        vertical: () => ({
          [`& .${tabClasses.root}`]: {
            minHeight: 'auto',
          },
        }),
        indicator: ({ theme, ownerState }) => {
          return (
            ownerState?.orientation === 'horizontal' && {
              height: theme.spacing(0.375),
            }
          );
        },
        root: ({ theme }) => ({
          [`& .${tabClasses.root}`]: {
            marginInline: theme.spacing(1.5),
            [`&:first-of-type`]: {
              marginInlineStart: 0,
            },
            [`&:last-of-type`]: {
              marginInlineEnd: 0,
            },
            paddingInline: 0,
            minWidth: 'auto',
          },
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.info.main,
        }),
        thumbColorPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.primary.contrastText,
          borderColor: 'currentcolor',
          borderStyle: 'solid',
          borderWidth: theme.spacing(0.5),
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <CheckCircleIcon />,
          error: <AlertIcon />,
          warning: <WarningTriangleIcon />,
          info: <InformationIcon />,
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          paddingInline: theme.spacing(2.5),
          paddingBlock: theme.spacing(2),
          borderRadius: theme.spacing(1),
        }),
        message: ({ theme }) => ({
          ...theme.typography.body2,
          padding: 0,
        }),
        icon: ({ theme }) => ({
          marginInlineEnd: theme.spacing(2),
          padding: 0,
        }),
        outlined: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        outlinedSuccess: ({ theme }) => ({
          borderColor: theme.palette.success.main,
          [`& .${alertClasses.icon}`]: {
            color: theme.palette.success.main,
          },
        }),
        outlinedInfo: ({ theme }) => ({
          borderColor: theme.palette.info.main,
          [`& .${alertClasses.icon}`]: {
            color: theme.palette.info.main,
          },
        }),
        outlinedWarning: ({ theme }) => ({
          borderColor: theme.palette.warning.main,
          [`& .${alertClasses.icon}`]: {
            color: theme.palette.warning.main,
          },
        }),
        outlinedError: ({ theme }) => ({
          borderColor: theme.palette.error.main,
          [`& .${alertClasses.icon}`]: {
            color: theme.palette.error.main,
          },
        }),
        standardError: ({ theme }) => ({
          color: theme.palette.error['160p'],
          backgroundColor: theme.palette.error['190p'],
          [`& .${alertClasses.icon}`]: {
            color: theme.palette.error.dark,
          },
        }),
        standardSuccess: ({ theme }) => ({
          color: theme.palette.success['160p'],
          backgroundColor: theme.palette.success['190p'],
          [`& .${alertClasses.icon}`]: {
            color: theme.palette.success.dark,
          },
        }),
        standardInfo: ({ theme }) => ({
          color: theme.palette.info['160p'],
          backgroundColor: theme.palette.info['190p'],
          [`& .${alertClasses.icon}`]: {
            color: theme.palette.info.dark,
          },
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.warning['160p'],
          backgroundColor: theme.palette.warning['190p'],
          [`& .${alertClasses.icon}`]: {
            color: theme.palette.warning.dark,
          },
        }),
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 550,
          fontSize: theme.spacing(2),
          lineHeight: 1.5,
          marginBottom: theme.spacing(0.25),
          marginTop: 0,
        }),
      },
    },
    MuiDialog: {
      defaultProps: {
        slotProps: {
          backdrop: {
            sx: {
              background:
                'linear-gradient(to bottom, hsla(257, 20%, 10%, 0.3), hsla(257, 20%, 10%, 0.5))',
            },
          },
        },
      },
      styleOverrides: {
        paperFullScreen: {
          borderRadius: 0,
        },
        paper: ({ theme }) => ({
          borderRadius: theme.spacing(1.5),
        }),
        paperWidthXs: () => ({
          [`& .${dialogActionsClasses.root}  button`]: {
            width: '100%',
          },
        }),
        paperWidthSm: () => ({
          [`& .${dialogActionsClasses.root} button`]: {
            width: '100%',
          },
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingBlockStart: theme.spacing(3),
          paddingBlockEnd: theme.spacing(1),
          paddingInline: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            paddingInline: theme.spacing(2),
          },
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingBlock: 0,
          paddingInline: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            paddingInline: theme.spacing(2),
          },
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingBlockStart: theme.spacing(1),
          paddingBlockEnd: theme.spacing(3),
          paddingInline: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            paddingInline: theme.spacing(2),
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.common.black,
          paddingInline: theme.spacing(1.5),
          paddingBlock: theme.spacing(1),
          fontWeight: 300,
          fontSize: theme.spacing(1.5),
          lineHeight: 1.33333,
          borderRadius: theme.spacing(1),
          boxShadow: theme.shadows[6],
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.common.black,
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        IconComponent: ChevronDownIcon,
      },
      styleOverrides: {
        select: ({ theme }) => ({
          padding: 0,
          minHeight: theme.spacing(3),
        }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ChevronDownIcon />,
      },
      styleOverrides: {
        inputRoot: ({ theme }) => ({
          padding: theme.spacing(1.5),
          [`& .${inputBaseClasses.input}`]: {
            padding: 0,
          },
        }),
        tag: ({ theme }) => ({
          marginBlock: 0,
          height: theme.spacing(3),
        }),
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 1,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          [`& .${backdropClasses.root}`]: {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 300,
          fontSize: theme.spacing(2),
          lineHeight: 1.5,
          letterSpacing: theme.spacing(0.01875),
        }),
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {},
        },
      ],
      styleOverrides: {
        standardError: ({ theme }) => ({
          backgroundColor: 'hsla(0, 59%, 94%, 1)',
          color: theme.palette.error.main,
        }),
        standardWarning: ({ theme }) => ({
          backgroundColor: 'hsla(28, 86%, 95%, 1)',
          color: theme.palette.warning.main,
        }),
        standardInfo: ({ theme }) => ({
          backgroundColor: 'hsla(259, 80%, 96%, 1)',
          color: theme.palette.info.main,
        }),
        standardSuccess: ({ theme }) => ({
          backgroundColor: 'hsla(145, 49%, 93%, 1)',
          color: theme.palette.success.main,
        }),
        label: ({ theme }) => ({
          fontWeight: 400,
          fontSize: theme.spacing(1.75),
          lineHeight: 1.28571,
          letterSpacing: theme.spacing(0.02),
          paddingInline: theme.spacing(1.25),
        }),
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body2,
        }),
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottomWidth: theme.spacing(0.0625),
          borderTopWidth: theme.spacing(0.0625),
          [`&:first-of-type`]: {
            borderTopRightRadius: theme.spacing(1),
            borderTopLeftRadius: theme.spacing(1),
            borderTopWidth: theme.spacing(0.125),
          },
          [`&:last-of-type`]: {
            borderBottomRightRadius: theme.spacing(1),
            borderBottomLeftRadius: theme.spacing(1),
            borderBottomWidth: theme.spacing(0.125),
          },
          '&:before': {
            content: 'none',
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: ({ theme }) => ({
          margin: theme.spacing(2, 0),
        }),
      },
    },
    MuiDesktopDatePicker: {
      defaultProps: {
        format: 'PP',
        slotProps: {
          desktopPaper: {
            elevation: 6,
          },
        },
        slots: {
          leftArrowIcon: ChevronLeftIcon,
          rightArrowIcon: ChevronRightIcon,
          switchViewIcon: ChevronDownIcon,
          openPickerIcon: CalendarIcon,
        },
      },
    },
    //TODO: these types comes from data-grid, version 5
    // the new merchant app uses version 6
    // we need to update that in both the ui package and the ops app
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        // @ts-ignore
        slotProps: {
          row: {
            style: {
              cursor: 'pointer',
            },
          },
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(1),
          [`& .tabular-numbers`]: {
            fontVariantNumeric: 'tabular-nums',
          },
        }),
        columnHeaders: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
        columnHeaderTitle: ({ theme }) => ({
          fontSize: theme.spacing(1.75),
          fontWeight: 300,
          lineHeight: 1.71429,
          letterSpacing: theme.spacing(0.02125),
        }),
        cellContent: ({ theme }) => ({
          ...theme.typography.body2,
        }),
        columnSeparator: ({ theme }) => ({
          display: 'none',
        }),
      },
    },
  },
};

export default mainThemeOptions;
