import { MerchantWithLink, OrderStatusChip } from '@/components';
import { ROUTES } from '@/constants';
import { Order } from '@/schemas/getGlobalSearchResults';
import { Link as MuiLink, Stack, Typography } from '@mui/material';
import { DataGrid, GridColumns, gridClasses } from '@mui/x-data-grid';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import PackageIcon from '@treyd-io/treyd-ui/icons/PackageIcon';
import { isEmpty, toNumber, toString } from 'lodash';
import { Link } from 'react-router-dom';

const columns: GridColumns<Order> = [
  {
    field: 'id',
    headerName: 'Order #',
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <MuiLink
        component={Link}
        to={`${ROUTES.orders}/${params.id}`}
        variant="body2">
        {params.value}
      </MuiLink>
    ),
  },
  {
    field: 'created',
    headerName: 'Created',
    type: 'date',
    sortable: false,
    flex: 1,
    valueGetter: ({ value }) => value && formatDate(new Date(value), 'date'),
  },
  {
    field: 'mapped_status',
    headerName: 'Status',
    type: 'string',
    sortable: false,
    minWidth: 200,
    flex: 1,
    renderCell: (params) => (
      <OrderStatusChip status={params.value} size="small" />
    ),
  },
  {
    field: 'importer__name',
    headerName: 'Merchant',
    type: 'string',
    sortable: false,
    minWidth: 200,
    flex: 1,
    renderCell: (params) => {
      const id = params.row?.importer?.id;
      const name = params.row?.importer?.name;
      const segment = params.row?.importer?.segment;

      return name && <MerchantWithLink id={id} name={name} segment={segment} />;
    },
  },
  {
    field: 'importer__country',
    headerName: 'Country',
    type: 'string',
    sortable: false,
    flex: 1,
    valueGetter: (params) => getCountryName(params.row?.importer?.country),
  },
  {
    field: 'financier__name',
    headerName: 'Financier',
    type: 'string',
    sortable: false,
    flex: 1,
    valueGetter: ({ row }) => row.financier?.name,
  },
  {
    field: 'active_payout__expected_payment_date',
    headerName: 'Expected payment date',
    type: 'date',
    sortable: false,
    flex: 1,
    valueGetter: (params) => {
      const expectedPaymentDate =
        params.row?.active_payout?.expected_payment_date;
      return (
        expectedPaymentDate && formatDate(new Date(expectedPaymentDate), 'date')
      );
    },
  },
  {
    field: 'active_payout__expected_settlement_date_calc',
    headerName: 'Expected repayment date',
    type: 'date',
    sortable: false,
    flex: 1,
    valueGetter: (params) => {
      const expectedSettlementDate =
        params.row?.active_payout?.expected_settlement_date_calc;
      return (
        expectedSettlementDate &&
        formatDate(new Date(expectedSettlementDate), 'date')
      );
    },
  },
  {
    field: 'active_payout__actual_payment_date',
    headerName: 'Actual payment date',
    type: 'date',
    sortable: false,
    flex: 1,
    valueGetter: (params) => params.row?.active_payout?.actual_payment_date,
    valueFormatter: (params) => params.value,
  },
  {
    field: 'active_payout__amount',
    headerName: 'Payout amount',
    type: 'number',
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography variant="body2" sx={{ fontVariantNumeric: 'tabular-nums' }}>
        {formatCurrency(
          params.row?.active_payout?.amount,
          toString(params.row?.active_payout?.currency)
        )}
      </Typography>
    ),
  },
];

export const OrdersTable = ({
  orders,
  searchTerm,
}: {
  orders?: Order[];
  searchTerm?: string;
}) => {
  if (isEmpty(orders)) return null;

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <PackageIcon />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          flexWrap="wrap">
          <Typography variant="body1">Orders</Typography>
          <MuiLink
            component={Link}
            to={`${ROUTES.orders}?searchTerm=${searchTerm}`}
            variant="body2">
            Show more ↗︎
          </MuiLink>
        </Stack>
      </Stack>
      <Stack height={toNumber(orders?.length) <= 4 ? 'auto' : 185}>
        <DataGrid
          rows={orders || []}
          columns={columns}
          disableSelectionOnClick
          hideFooter
          autoHeight={toNumber(orders?.length) <= 4}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.columnHeader}:focus`]:
              {
                outline: 'none',
              },
          }}
        />
      </Stack>
    </Stack>
  );
};
