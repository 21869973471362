import {
  MerchantLimitStatusChip,
  MerchantStatusChip,
  MerchantWithLink,
} from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { PAGE_SIZE_OPTIONS } from '@/constants';
import { useGetMerchants, useGetTableSettings } from '@/hooks';
import { Merchant } from '@/schemas/getMerchantsQuery';
import { Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toNumber, toString } from 'lodash';
import { useMemo } from 'react';

export const MerchantsTable = ({ filters }: { filters?: Filter[] }) => {
  const {
    merchantsTableSettings: {
      sortModel,
      pageSize,
      page,
      setSortModel,
      setPageSize,
      setPage,
    },
  } = useGetTableSettings();

  const columns: GridColDef<Merchant>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 250,
        renderCell: (params) => {
          const id = params.row?.id;
          const name = params.row?.name;
          const segment = params.row?.segment;
          return (
            name && <MerchantWithLink id={id} name={name} segment={segment} />
          );
        },
      },
      {
        field: 'org_no',
        headerName: 'Org #',
        flex: 0,
        minWidth: 150,
      },
      {
        field: 'is_active',
        headerName: 'Limit status',
        minWidth: 100,
        renderCell: (params) => (
          <MerchantLimitStatusChip
            size="small"
            isActive={params.value}
            orgNumber={params?.row?.org_no}
          />
        ),
      },
      {
        field: 'active_combined_status__status',
        headerName: 'Status',
        sortable: false,
        minWidth: 270,
        renderCell: (params) => {
          const status = params?.row?.active_combined_status?.status;
          const label = params?.row?.active_combined_status?.label;
          return (
            status && (
              <MerchantStatusChip status={status} label={label} size="small" />
            )
          );
        },
      },
      {
        field: 'country',
        headerName: 'Country',
        flex: 1,
        minWidth: 130,
        renderCell: (params) => getCountryName(params.value),
      },
      {
        field: 'financier__name',
        headerName: 'Financier',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => params.row?.financier?.name,
      },
      {
        field: 'created_at',
        headerName: 'Joining date',
        minWidth: 100,
        flex: 1,
        renderCell: (params) =>
          params.value && formatDate(new Date(params.value), 'date'),
      },
      {
        field: 'orders_aggregate.count',
        headerName: '# Orders',
        sortable: true,
        minWidth: 80,
        flex: 1,
        renderCell: (params) => params.row?.orders_aggregate?.aggregate?.count,
      },
      {
        field: 'rating',
        headerName: 'Rating',
        minWidth: 80,
        flex: 1,
      },
      {
        field: 'limit',
        headerName: 'Credit limit',
        minWidth: 150,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) =>
          params.value === null
            ? ''
            : formatCurrency(
                toNumber(params.value),
                toString(params.row?.limit_currency)
              ),
      },
    ],
    []
  );

  const { data, loading: isMerchantsLoading } = useGetMerchants({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: pageSize,
      offset: page * pageSize,
    },
  });

  const merchants = data?.orders_companies || [];
  const rowCount = data?.orders_companies_aggregate?.aggregate?.count || 0;

  const noMerchantsProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack flexGrow={1} height={!rowCount ? 400 : 'auto'} width="100%">
      <DataGrid
        rows={merchants}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCount}
        pageSize={pageSize}
        page={page}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        sortModel={sortModel}
        loading={isMerchantsLoading}
        onSortModelChange={setSortModel}
        components={{
          NoRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'No merchants found'}
              subtitle={'Merchants will be displayed here'}
            />
          ),
          ...noMerchantsProps,
        }}
        {...noMerchantsProps}
      />
    </Stack>
  );
};
