import {
  COMPANY_LIMIT_STATUS,
  COMPANY_STATUS,
  KYC_STATUS,
  MERCHANT_LIMIT_STATUS,
} from '@/constants';
import { ChipProps, SvgIconProps } from '@mui/material';
import {
  Lager_Core_Beneficialowner,
  Lager_Core_Boardmember,
  Lager_Core_Member,
  Lager_Core_Signatory,
  Maybe,
} from '__generated__/graphql';
import { ReactElement, ReactNode } from 'react';
import { Onfido } from './onfido';
import { TreydUser, User } from './user';

export type CompanyLimitStatus = keyof typeof COMPANY_LIMIT_STATUS;

export type CompanyStatus = keyof typeof COMPANY_STATUS;

export type CompanyKycStatus = 'REVOKED' | 'DRAFT' | 'ACTIVE';
export interface CompanyStatusesResponse {
  limit_status: CompanyLimitStatus;
  company_statuses: CompanyStatus[];
  kyc_status: CompanyKycStatus;
  can_add_order: boolean;
  can_sign_order: boolean;
}

export interface CompanyAppliedDiscounts {
  manual_discount: number;
  esg_discount: number;
  size_discount: number;
  base_price: number;
}

export interface CompanyManualDiscountChargesPayload {
  type: 'charges' | 'discount';
  value: number;
}
export interface CompanyFinancier {
  financier_id: number;
  financier_name: string;
  financier_priority: 'PRIMARY' | 'SECONDARY' | 'TERTIARY';
  limit: {
    limit: number;
    limit_currency: string;
    new_remaining: number;
    outstanding_limit: number;
  };
}
export interface AddCompanyForm {
  org_no: string;
  country: string;
}

export interface CoreCompanyResponseData {
  address: string;
  city: string;
  country: string;
  registration_date: string;
  name: string;
  legal_form: string;
  operational_status: string;
  orgnr: string;
  error_code: number;
}
export type CompanyExistsResponse = { exists: boolean };

export type CompanyFinanciersResponse = CompanyFinancier[];

export namespace Documents {
  export interface DocumentType {
    id: number;
    title: string;
    status: string;
    template_id: number;
    signers: User[];
    pdf_url: string;
    data: {
      Org_No: string;
      User_ID: number;
      Signer_ID: number;
      User_Name: string;
      Company_Name: string;
      User_Identity_Type: string;
      User_Identity_Reference: string;
      Guarantor_Name: number;
    };
    schema: {
      type: string;
      properties: {
        Comments: {
          type: string;
          title: string;
          field_type: string;
        };
        Attachement: {
          type: string;
          title: string;
          format: string;
          field_type: string;
        };
      };
    };
    ui_schema: null;
    digest: string | null;
    tag: string | null;
    step: null;
    date: null;
    internal: boolean;
    documentable_id: number;
    documentable_type: string;
    signer_id: null;
    power_of_attorney_id: null;
    created_by: number;
    created_at: string;
    updated_at: string;
    event_base64_attachments: {
      filename: string;
      url: string;
      preview_url: string;
    }[];
    creator: User;
  }

  export type DocumentResponse = DocumentType[];

  export interface Payload {
    signer_id: number;
    user_id: number;
    companyId: number;
  }
  export interface ResendDocumentEmailsPayload {
    companyId: number;
    documentId: number;
  }
}
export interface Company {
  id: number;
  country: string;
  name?: string;
  org_no?: string;
  invoicing_currency?: string;
  avatar_url?: string;
  financier_id?: number;
  created_at?: Date;
  updated_at?: Date;
  limit?: number;
  monthly_interest_rate?: number;
  sales_representative_id?: number;
  sales_representative?: User;
  rating_data?: {
    treyd?: {
      expiration_date: string;
    };
  };
  users?: Array<User>;
  financer_users?: Array<unknown>;
  attachments?: Array<unknown>;
  company_info?: unknown;
  status?: string;
  bank_account?: unknown;
  is_active: boolean;
  remaining_limit?: number;
}

export interface CompanyInformation {
  name: string;
  org_no: string;
  address: string;
  zip_code: string;
  country: string;
  city: string;
  phone_number: string;
}

export interface CompanySigner {
  name: string;
  email: string;
  personal_no: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  citizenship: string[];
  country_of_residence: string;
}

export interface UltimateBeneficialOwner {
  name: string;
  email: string;
  personal_no: string;
  tax_liable_country: string[];
  citizenship: string[];
  address: string;
  zip_code: string;
  city: string;
  country: string;
  pep: string;
  country_of_residence: string;
}

export interface SupplierOwnership {
  supplier_ownership_name: string;
  supplier_ownership_percentage: string;
}

export interface ImportedProduct {
  type: string;
  country: string;
}

export interface CustomerSale {
  customer_sales_name: string;
  customer_sales_percentage: string;
}

export interface CompanyOperations {
  imported_products: ImportedProduct[];
  yearly_number_of_orders: string;
  size_per_order: string;
  ownership_structure: string;
  forms_of_financing: string;
  customer_sales: boolean;
  company_handle_cash: boolean;
  monthly_revenue: string;
  monthly_revenue_currency: string;
  business_industry: string[];
  industry_category: string;
  industry_category_details: string;
  customer_sales_optional: CustomerSale[];
  supplier_ownership: boolean;
  supplier_ownership_optional: SupplierOwnership[];
  online_service: string;
}

export interface KycData extends CompanyOperations {
  company_info: CompanyInformation;
  company_signers: CompanySigner[];
  owners: UltimateBeneficialOwner[];
}

export interface KycMetaData {
  status: KYC_STATUS;
  submited_by?: number;
  revoked_at?: string;
  version: number;
}

export interface MerchantKyc extends KycMetaData {
  data: KycData;
}

export type CompanyCoreMemberType = Pick<
  Lager_Core_Member,
  'id' | 'name_id' | 'personal_number' | 'citizenship' | 'name'
>;

export type Signatory = Pick<Lager_Core_Signatory, 'member_id'>;
export type CompanyCoreSignatoryMemberType = CompanyCoreMemberType & {
  core_signatories: Signatory[];
};

export type BeneficialOwner = Pick<Lager_Core_Beneficialowner, 'member_id'>;
export type CompanyCoreBeneficialOwnerMemberType = CompanyCoreMemberType & {
  core_beneficialowners: BeneficialOwner[];
};

export type BoardMember = Pick<Lager_Core_Boardmember, 'member_id'>;
export type CompanyCoreBoardMemberType = CompanyCoreMemberType & {
  core_boardmembers: BoardMember[];
};

export interface RolesType {
  label: string;
  color: 'success' | 'warning' | 'info' | 'default';
  variant?: 'filled' | 'standard';
}

export type InviteUserPayload = {
  company_id?: string;
  email?: Maybe<string>;
  name?: string;
};

export type CompanyData = {
  lager_treyd_users: Array<
    TreydUser & {
      onfido_applicant: {
        onfido_check: {
          reports_payload: {
            reports: Onfido.Report[];
          };
        };
      };
    }
  >;
};

export interface CompanyCredits {
  id: number;
  company_id: number;
  created: string;
  modified: string;
  creditable_type: string;
  creditable_id: number;
  amount: string;
  reason: string;
  idempotency_key: string | null;
  voucher_slug: string | null;
  invoice: {
    id: string;
    number: string;
  } | null;
}
export interface GetCompanyCreditsType {
  next: string | null;
  previous: string | null;
  count: number;
  results: CompanyCredits[];
}

//Limit status types

export namespace Codes {
  export type Credit =
    | 'CREDIT_DECISION_NOT_STARTED'
    | 'CREDIT_DECISION_INACTIVE'
    | 'CREDIT_DECISION_REJECTED'
    | 'NEW_CREDIT_DECISION_IN_STATUS'
    | 'REQUIREMENTS_NEEDED'
    | 'PUBLISHED_INDICATIVE_LIMIT'
    | 'CURRENT_PUBLISHED_CREDIT_DECISION_ACTIVE'
    | 'UP_COMING_LIMIT_RENEWAL'
    | 'CREDIT_DECISION_EXPIRED';

  export type Compliance =
    | 'COMPLIANCE_NOT_STARTED'
    | 'COMPLIANCE_DECISION_STARTED'
    | 'COMPLIANCE_DECISION_APPROVED'
    | 'COMPLIANCE_DECISION_DECLINED'
    | 'COMPLIANCE_DECISION_INACTIVE'
    | 'UBO_NOT_INVITED'
    | 'KYC_NOT_SUBMITTED'
    | 'KYC_SUBMITTED'
    | 'KYC_REVOKED'
    | 'KYC_EXPIRING_SOON'
    | 'AT_LEAST_ONE_OTHER_USER_NEEDS_TO_BE_INVITED'
    | 'ONE_OTHER_UBO_OR_SIGNATORY_NEEDS_TO_BE_INVITED'
    | 'SIGNATORY_NOT_INVITED';
  export type Funding =
    | 'FUNDING_SECURED'
    | 'FUNDING_NOT_SECURED'
    | 'FUNDING_NOT_STARTED';
  export type Monitoring =
    | 'NO_DATA_AVAILABLE'
    | 'COMPANY_HAS_OVERDUE_INVOICE'
    | 'COMPANY_HAS_NO_OVERDUE_INVOICE'
    | 'COMPANY_CREDITSAFE_INACTIVE'
    | 'COMPANY_HAS_NO_PAYMENT_REMARKS'
    | 'COMPANY_HAS_PAYMENT_REMARKS'
    | 'COMPANY_CREDITSAFE_ACTIVE';
}

export namespace LimitStatusSegmentArgs {
  export interface Base {
    expiration_date: string;
    reason: string;
  }

  export enum CREDIT_SUB_STATUS {
    REVIEW_IN_REVIEW = 'review_inreview',
    REVIEW_PENDING = 'review_pending',
    REVIEW_NOT_STARTED = 'review_notstarted',
    REVIEW_SENT_BACK = 'review_sentback',
    REVIEW_BLOCKED = 'review_blocked',
    UNDERWRITING_UNASSIGNED = 'underwriting_unassigned',
    UNDERWRITING_NOT_STARTED = 'underwriting_notstarted',
    UNDERWRITING_IN_ANALYSIS = 'underwriting_inanalysis',
    UNDERWRITING_SENT_BACK = 'underwriting_sentback',
    UNDERWRITING_CANCELLED = 'underwriting_cancelled',
    UNDERWRITING_BLOCKED = 'underwriting_blocked',
    OPEN_DECISION = 'opendecision',
    APPROVED = 'approved',
  }
  export interface Credit extends Base {
    limit: number;
    currency: string;
    published_at: string;
    deactivated_at: string | null;
    permanent: boolean;
    status: CREDIT_SUB_STATUS;
  }
  export interface Compliance extends Base {
    submitted_at: string;
    submitted_by: string;
    approved_at: string;
    risk_level: string;
    revoked_at: string;
  }
  export interface Funding {
    reason: string;
    financier: string;
  }
  export interface Monitoring {
    descriptrion: string;
    balance: number;
    currency: string;
  }
}

export namespace LimitStatus {
  export type Status = keyof typeof MERCHANT_LIMIT_STATUS;
  export interface Response {
    status: Status;
    credit: SegmentResponse<LimitStatusSegmentArgs.Credit, Codes.Credit>;
    compliance: SegmentResponse<
      LimitStatusSegmentArgs.Compliance,
      Codes.Compliance
    >;
    funding: SegmentResponse<LimitStatusSegmentArgs.Funding, Codes.Funding>;
    monitoring: SegmentResponse<
      LimitStatusSegmentArgs.Monitoring,
      Codes.Monitoring
    >;
  }
  export enum SEGMENT_STATUS {
    PUBLISHED = 'PUBLISHED',
    INACTIVE = 'INACTIVE',
    NOT_STARTED = 'NOT_STARTED',
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    INDICATIVE = 'INDICATIVE',
    WARNING = 'WARNING',
  }

  export type Segments = Omit<Response, 'status'>;

  type PossibleCodes =
    | Codes.Credit
    | Codes.Compliance
    | Codes.Funding
    | Codes.Monitoring;

  interface UICodes<ArgsType = unknown, CodeType = PossibleCodes> {
    code: CodeType;
    kargs: ArgsType;
  }

  interface SegmentResponse<ArgsType = unknown, CodeType = PossibleCodes> {
    status: Segment.Status;
    uiCodes: UICodes<ArgsType, CodeType>[];
  }
}

export namespace Segment {
  export interface Values {
    icon: ReactElement<SvgIconProps>;
    points: ReactNode[];
    alerts?: ReactNode[];
    chip?: ReactElement<ChipProps> | null;
  }
  export type Keys = keyof LimitStatus.Segments;
  export type Status = keyof typeof LimitStatus.SEGMENT_STATUS;
}
