import { ROUTES } from '@/constants';
import { Invoice } from '@/schemas/getGlobalSearchResults';
import { Link as MuiLink, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import FilesIcon from '@treyd-io/treyd-ui/icons/FileIcon';
import { isEmpty, startCase, toNumber, toString } from 'lodash';
import { Link } from 'react-router-dom';
import { MerchantWithLink } from '../MerchantWithLink';

const columns: GridColDef<Invoice>[] = [
  {
    field: 'invoice_number',
    headerName: 'Invoice #',
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <MuiLink
        component={Link}
        to={`${ROUTES.invoices}/${params.id}`}
        variant="body2">
        {params.value}
      </MuiLink>
    ),
  },
  {
    field: 'order_id',
    headerName: 'Order #',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <MuiLink
        component={Link}
        to={`${ROUTES.orders}/${row.order_id}`}
        variant="body2">
        {row.order_id}
      </MuiLink>
    ),
  },
  {
    field: 'order__importer__name',
    headerName: 'Merchant',
    sortable: false,
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) => {
      const id = row.order?.importer?.id;
      const name = row.order?.importer?.name;
      const segment = row.order?.importer?.segment;

      return name && <MerchantWithLink id={id} name={name} segment={segment} />;
    },
  },
  {
    field: 'due_date',
    headerName: 'Due date',
    sortable: false,
    minWidth: 200,
    valueGetter: ({ value }) => value && formatDate(new Date(value), 'date'),
  },
  {
    field: 'invoice_type',
    headerName: 'Type',
    minWidth: 200,
    sortable: false,
    cellClassName: 'tabular-numbers',
    valueGetter: ({ value }) => startCase(toString(value)),
  },
  {
    field: 'financier__name',
    headerName: 'Financier',
    minWidth: 200,
    sortable: false,
    valueGetter: ({ row }) => row?.financier?.name,
  },
  {
    field: 'invoicing_system',
    headerName: 'Invoicing system',
    minWidth: 200,
    sortable: false,
  },
  {
    field: 'ops_status',
    headerName: 'Status',
    sortable: false,
    valueGetter: ({ value }) => startCase(toString(value)),
  },
  {
    field: 'invoiced_amount',
    headerName: 'Invoice amount',
    width: 150,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'tabular-numbers',
    valueGetter: (params) =>
      formatCurrency(params.value, toString(params.row?.invoiced_currency)),
  },
];

export const InvoicesTable = ({
  invoices,
  searchTerm,
}: {
  invoices?: Invoice[];
  searchTerm?: string;
}) => {
  if (isEmpty(invoices)) return null;

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <FilesIcon />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          flexWrap="wrap">
          <Typography variant="body1">Invoices</Typography>
          <MuiLink
            component={Link}
            to={`${ROUTES.invoices}?searchTerm=${searchTerm}`}
            variant="body2">
            Show more ↗︎
          </MuiLink>
        </Stack>
      </Stack>
      <Stack height={toNumber(invoices?.length) <= 4 ? 'auto' : 185}>
        <DataGrid
          rows={invoices || []}
          columns={columns}
          disableSelectionOnClick
          hideFooter
          autoHeight={toNumber(invoices?.length) <= 4}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.columnHeader}:focus`]:
              {
                outline: 'none',
              },
          }}
        />
      </Stack>
    </Stack>
  );
};
