import { gql, TypedDocumentNode } from '@apollo/client';
import { GridSortItem } from '@mui/x-data-grid';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Companies,
  Orders_Orders_Aggregate,
  Orders_Suppliers,
  Orders_Suppliers_Aggregate,
} from '__generated__/graphql';

export type Supplier = Pick<
  Orders_Suppliers,
  'id' | 'created_at' | 'name' | 'country' | 'exempted_from_inspections' | 'kyc'
> & {
  company: Pick<Orders_Companies, 'name'>;
};

type getMerchantSuppliersQueryTypes = {
  supplier: Array<Supplier>;
  allDeals: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  paidDeals: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  suppliers_aggregate: Pick<Orders_Suppliers_Aggregate, 'aggregate'>;
};

export const getMerchantSuppliersQuery = (
  offset: number,
  limit: number,
  company_id?: number,
  searchTerm: string = '',
  sorting = {},
  filters = {
    country: '',
  }
) => {
  let merchantSuppliersFilters: Filter[] = [
    {
      type: 'search',
      name: 'name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'country',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'filter',
      name: 'company_id',
      comparator: FilterComparator.IN,
      comparisonValue: company_id || '',
    },
  ];

  if (filters?.country) {
    merchantSuppliersFilters.push({
      type: 'filter',
      name: 'country',
      comparator: FilterComparator.IN,
      comparisonValue: filters?.country,
    });
  }

  const { queryParams, whereClause } = getGqlQueryParams({
    limit,
    offset,
    sorting: sorting as GridSortItem,
    filters: merchantSuppliersFilters,
  });
  return gql`
  query getMerchantSuppliersQuery {
     orders_suppliers ${queryParams} {
      id
      name
      country
			created_at
      exempted_from_inspections
			kyc

      allDeals: orders_aggregate {
      aggregate {
        count
      }
    }
			paidDeals: orders_aggregate(where: {status: {_in: ["paid", "overdue", "scheduled", "closed", "settled", "open"]}}) {
				aggregate {
					count
				}
			}
	}

    orders_suppliers_aggregate (where: ${whereClause}) {
      aggregate {
        count
      }
    }
  }
  ` as TypedDocumentNode<getMerchantSuppliersQueryTypes, {}>;
};
