import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '@/constants';
import { useGetMerchantSuppliersErpInvoices } from '@/hooks/useGetMerchantSuppliersErpInvoices';
import { MerchantSupplierErpInvoice } from '@/schemas/getMerchantSuppliersErpInvoicesQuery';
import { Stack } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toString } from 'lodash';
import { useMemo, useState } from 'react';

export const SuppliersInvoicesTable = ({ filters }: { filters?: Filter[] }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'desc' },
  ]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const { data, loading: isLoading } = useGetMerchantSuppliersErpInvoices({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: pageSize,
      offset: page * pageSize,
    },
  });

  const invoices = data?.unified_supplier_invoice || [];
  const rowCount =
    data?.unified_supplier_invoice_aggregate?.aggregate?.count || 0;

  const noInvoicesProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  const columns: GridColDef<MerchantSupplierErpInvoice>[] = useMemo(
    () => [
      {
        field: 'invoice_reference',
        headerName: 'Invoice reference',
        minWidth: 90,
        flex: 1,
      },
      {
        field: 'supplier_name',
        headerName: 'Supplier name',
        minWidth: 90,
        flex: 2,
      },
      {
        field: 'invoice_status',
        headerName: 'Status',
        maxWidth: 90,
      },
      {
        field: 'total_amount',
        headerName: 'Total amount',
        minWidth: 150,
        renderCell: ({ row }) =>
          formatCurrency(row.total_amount, toString(row.currency)),
      },
      {
        field: 'balance',
        headerName: 'Balance',
        maxWidth: 90,
        renderCell: ({ row }) =>
          formatCurrency(row.balance, toString(row.currency)),
      },
      {
        field: 'issue_date',
        headerName: 'Issue date',
        maxWidth: 100,
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
      {
        field: 'due_date',
        headerName: 'Due date',
        maxWidth: 100,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
    ],
    []
  );
  return (
    <Stack flexGrow={1} height={400} width="100%">
      <DataGrid
        rows={invoices}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        onPageChange={setPage}
        rowCount={rowCount}
        pageSize={pageSize}
        getRowId={(row) => row.uuid}
        page={page}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        onPageSizeChange={setPageSize}
        sortModel={sortModel}
        loading={isLoading}
        onSortModelChange={setSortModel}
        components={{
          NoRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'Nothing here yet'}
              subtitle={'Suppliers invoices will be displayed here'}
            />
          ),
          ...noInvoicesProps,
        }}
        {...noInvoicesProps}
      />
    </Stack>
  );
};
