import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import UserAvatar from '@/components/UserAvatar';
import {
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  treydSystemEmail,
} from '@/constants';
import { useGetOrdersActivityLogs } from '@/hooks/useGetOrdersActivityLogs';
import { OrderActivityLog } from '@/schemas/getOrdersActivityLogsQuery';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { FilterComparator } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { toNumber, toString } from 'lodash';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ExpandableCell from './ExpandableCell';

interface OrdersActivityLogsProps {
  merchantId: number;
}

export const OrdersActivityLogs = (props: OrdersActivityLogsProps) => {
  const { merchantId } = props;

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'desc' },
  ]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const { data, loading: isLoadingOrders } = useGetOrdersActivityLogs({
    queryOptions: {
      filters: [
        {
          type: 'filter',
          name: 'user__email',
          comparator: FilterComparator.NOT_EQUAL,
          comparisonValue: treydSystemEmail,
        },
        {
          type: 'filter',
          name: 'order__importer_id',
          comparator: FilterComparator.EQUAL,
          comparisonValue: toNumber(merchantId),
        },
        {
          type: 'filter',
          name: 'type',
          comparator: FilterComparator.EQUAL,
          comparisonValue: 'comment',
        },
      ],
      sorting: sortModel[0],
      limit: pageSize,
      offset: page * pageSize,
    },
  });

  const orders = data?.orders_order_activity_logs || [];
  const rowCount =
    data?.orders_order_activity_logs_aggregate?.aggregate?.count || 0;

  const noOrdersProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  const columns: GridColDef<OrderActivityLog>[] = useMemo(
    () => [
      {
        field: 'loggable_id',
        headerName: 'Order #',
        minWidth: 190,
        renderCell: ({ value }) => (
          <Typography
            component={Link}
            to={`${ROUTES.orders}/${value}`}
            noWrap
            color="info"
            variant="body2">
            {value}
          </Typography>
        ),
      },
      {
        field: 'comment',
        headerName: 'Comment',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <ExpandableCell value={params.row?.data?.comment} />
        ),
      },
      {
        field: 'user',
        headerName: 'Reviewer',
        minWidth: 200,
        sortable: false,
        renderCell: (params) => {
          const firstName = toString(params.row?.user?.first_name).trim();
          const lastName = toString(params.row?.user?.last_name).trim();
          const name = `${firstName} ${lastName}`.trim();
          return (
            name && (
              <>
                <UserAvatar
                  sx={{
                    height: '2rem',
                    width: '2rem',
                    marginInlineEnd: '0.5rem',
                    fontSize: 12,
                  }}
                  firstName={firstName}
                  lastName={lastName}
                />
                <Typography>{name}</Typography>
              </>
            )
          );
        },
      },
      {
        field: 'created',
        headerName: 'Date',
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ value }) => {
          return value && formatDate(new Date(value), 'datetime');
        },
      },
    ],
    []
  );

  return (
    <Stack gap={2}>
      <Typography variant="body1">
        All comments added on orders by compliance will be displayed here.
      </Typography>
      <Stack flexGrow={1} height={400} width="100%">
        <DataGrid
          rows={orders}
          columns={columns}
          disableSelectionOnClick
          sortingMode="server"
          paginationMode="server"
          onPageChange={setPage}
          rowCount={rowCount}
          pageSize={pageSize}
          page={page}
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          onPageSizeChange={setPageSize}
          sortModel={sortModel}
          loading={isLoadingOrders}
          onSortModelChange={setSortModel}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}
          components={{
            NoRowsOverlay: () => (
              <TableHasNoRowsPlaceholder
                title={'Nothing here yet'}
                subtitle={'Orders will be displayed here'}
              />
            ),
            ...noOrdersProps,
          }}
          {...noOrdersProps}
        />
      </Stack>
    </Stack>
  );
};
