import { Routes } from '@/types';
import { mapValues, values } from 'lodash';
import { ROUTES } from './routes';

export enum ORDER_STATUS {
  DRAFT = 'draft',
  DISCARDED = 'discarded',
  PREVIEW = 'preview',
  AWAITING_APPROVAL = 'awaiting_approval',
  AWAITING_COMPLIANCE_APPROVAL = 'awaiting_compliance_approval',
  AWAITING_FUNDING_APPROVAL = 'awaiting_funding_approval',
  REJECTED = 'rejected',
  READY_FOR_PAYMENT = 'ready_for_payment',
  PAYMENT_PROCESSING = 'payment_processing',
  PAID = 'paid',
  OPEN = 'open',
  CLOSED = 'closed',
  CANCELED = 'canceled',
}

export const ORDER_STATUSES = values(ORDER_STATUS);

export enum MAPPED_ORDER_STATUS {
  DRAFT = 'draft',
  DISCARDED = 'discarded',
  PREVIEW = 'preview',
  AWAITING_APPROVAL = 'awaiting_approval',
  AWAITING_COMPLIANCE_APPROVAL = 'awaiting_compliance_approval',
  AWAITING_FUNDING_APPROVAL = 'awaiting_funding_approval',
  READY_FOR_PAYMENT = 'ready_for_payment',
  PAYMENT_PROCESSING = 'payment_processing',
  PAID = 'paid',
  INVOICE_OPEN = 'invoice_open',
  INVOICE_OVERDUE = 'invoice_overdue',
  INVOICE_COLLECTION = 'invoice_collection',
  CLOSED = 'closed',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}

export const ORDER_MAPPED_STATUSES = values(MAPPED_ORDER_STATUS);

export const ORDER_MAPPED_STATUS_DISPLAYED_VALUE = {
  [MAPPED_ORDER_STATUS.DRAFT]: 'Draft',
  [MAPPED_ORDER_STATUS.DISCARDED]: 'Discarded',
  [MAPPED_ORDER_STATUS.PREVIEW]: 'Preview',
  [MAPPED_ORDER_STATUS.AWAITING_APPROVAL]: 'Awaiting Approval',
  [MAPPED_ORDER_STATUS.AWAITING_COMPLIANCE_APPROVAL]:
    'Awaiting Compliance Approval',
  [MAPPED_ORDER_STATUS.AWAITING_FUNDING_APPROVAL]: 'Awaiting Funding Approval',
  [MAPPED_ORDER_STATUS.READY_FOR_PAYMENT]: 'Ready for Payment',
  [MAPPED_ORDER_STATUS.PAYMENT_PROCESSING]: 'Payment Processing',
  [MAPPED_ORDER_STATUS.PAID]: 'Paid',
  [MAPPED_ORDER_STATUS.INVOICE_OPEN]: 'Invoice Open',
  [MAPPED_ORDER_STATUS.INVOICE_OVERDUE]: 'Invoice Overdue',
  [MAPPED_ORDER_STATUS.INVOICE_COLLECTION]: 'Invoice Collection',
  [MAPPED_ORDER_STATUS.CLOSED]: 'Closed',
  [MAPPED_ORDER_STATUS.REJECTED]: 'Rejected',
  [MAPPED_ORDER_STATUS.CANCELED]: 'Canceled',
};

export const CUTOFF_INVOICE_REPAYMENT_DUE_DAYS = 7;

const ORDERS_ROOT = ROUTES.orders;

export const ORDER_ROUTES: Routes = Object.freeze({
  ':id': `:id`,
});

export const ORDER_PATHS = mapValues(
  ORDER_ROUTES,
  (route) => `${ORDERS_ROOT}/${route}`
);

export const NO_TRACKING_STATES: MAPPED_ORDER_STATUS[] = [
  MAPPED_ORDER_STATUS.AWAITING_APPROVAL,
  MAPPED_ORDER_STATUS.AWAITING_COMPLIANCE_APPROVAL,
  MAPPED_ORDER_STATUS.REJECTED,
];
