import { ROUTES } from '@/constants';
import { Button, Stack, Typography } from '@mui/material';
import googleLogo from '@treyd-io/treyd-ui/assets/img/google.svg';
import logo from '@treyd-io/treyd-ui/assets/img/logo.svg';
import { useAuth } from 'auth';
import { GOOGLE_LOGIN_CLIENT_ID } from 'constants/api-endpoints';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const onClickLoginWithGoogle = useCallback(() => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const callback_endpoint = '/users/google/login/callback/';
    const callback_url = `${window.location.protocol}//${window.location.host}${callback_endpoint}`;

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ].join(' ');
    const params = {
      response_type: 'code',
      client_id: GOOGLE_LOGIN_CLIENT_ID as string,
      redirect_uri: callback_url,
      prompt: 'select_account',
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location = `${googleAuthUrl}?${urlParams}` as string & Location;
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(localStorage.getItem('callbackUrl') || ROUTES.home);
    }
  }, [isAuthenticated, navigate]);

  return (
    <Stack flexGrow={1} alignItems="center" justifyContent="center">
      <Stack
        maxWidth={480}
        width="100%"
        padding={5}
        border={1}
        borderColor="other.outlined_border"
        borderRadius={6}
        alignItems="center"
        textAlign="center"
        gap={2}>
        <img src={logo} alt="logo" width={60} height={60} />
        <Typography variant="h4">Ops</Typography>
        <Typography variant="body2">
          Log in with your Treyd SSO below.
        </Typography>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<img src={googleLogo} alt="google" />}
          onClick={onClickLoginWithGoogle}>
          Continue with Google
        </Button>
      </Stack>
    </Stack>
  );
}
