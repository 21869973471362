import { VoucherStatusChip } from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { PAGE_SIZE_OPTIONS, ROUTES } from '@/constants';
import { useGetTableSettings, useGetVouchers } from '@/hooks';
import { Voucher } from '@/schemas/getVouchersQuery';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { startCase, toNumber, toString } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export const VouchersTable = ({ filters }: { filters?: Filter[] }) => {
  const {
    vouchersTableSettings: {
      page,
      pageSize,
      sortModel,
      setPage,
      setPageSize,
      setSortModel,
    },
  } = useGetTableSettings();

  const columns: GridColDef<Voucher>[] = useMemo(
    () => [
      {
        field: 'slug',
        headerName: 'Voucher',
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          return params.value;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 150,
        renderCell: (params) => (
          <VoucherStatusChip status={params.value} size="small" />
        ),
      },
      {
        field: 'order_id',
        headerName: 'Order #',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => (
          <Typography
            component={Link}
            to={`${ROUTES.orders}/${params.value}`}
            noWrap
            variant="body2">
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          return startCase(params.value);
        },
      },
      {
        field: 'created',
        headerName: 'Creation date',
        minWidth: 120,
        flex: 1,
        renderCell: (params) =>
          params.value && formatDate(new Date(params.value), 'date'),
      },
      {
        field: 'value',
        headerName: 'Amount',
        minWidth: 200,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) =>
          formatCurrency(
            toNumber(params.value),
            toString(params.row?.currency)
          ),
      },
    ],
    []
  );

  const { data, loading: isVouchersLoading } = useGetVouchers({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: pageSize,
      offset: page * pageSize,
    },
  });

  const vouchers = data?.orders_vouchers_app_voucher || [];
  const rowCount =
    data?.orders_vouchers_app_voucher_aggregate?.aggregate?.count || 0;

  const noVouchersProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack flexGrow={1} height={!rowCount ? 400 : 'auto'} width="100%">
      <DataGrid
        rows={vouchers}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCount}
        pageSize={pageSize}
        page={page}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        sortModel={sortModel}
        loading={isVouchersLoading}
        onSortModelChange={setSortModel}
        components={{
          NoRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'No vouchers found'}
              subtitle={'Vouchers will be displayed here'}
            />
          ),
          ...noVouchersProps,
        }}
        {...noVouchersProps}
      />
    </Stack>
  );
};
