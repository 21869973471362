import { DEFAULT_COUNTRY, DEFAULT_CURRENCY } from '@/constants';
import {
  useGetBankDetails,
  useGetCurrencies,
  usePaymentsRequirements,
  useUpsertBankAccount,
} from '@/hooks';
import { UpsertBankAccountMutationType, getBankDetailsQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { COUNTRIES_OPTIONS } from '@treyd-io/core/constants/country';
import { getCountryName } from '@treyd-io/core/utils/country';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { useFormik } from 'formik';
import {
  find,
  indexOf,
  isArray,
  isEmpty,
  map,
  merge,
  omit,
  pick,
  reduce,
  some,
  startCase,
} from 'lodash';

import {
  createValidationSchema,
  fieldTitle,
  getBankIdentifyingCode,
  getExtraErrors,
  getPaymentRequirementsCombinations,
  getPaymentRequirementsDependencySchema,
  isValidBankIdentifyingCode,
} from '@/pages/suppliers/SupplierDetails/Tabs/BankInfoTab/util';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type BankDialogFormData = {
  currency: string;
  bank_country: string;
  payment_type: string;
  bank_name: string;
  bank_address: string;
  [key: string]: string;
};

const BankAccountForm = ({
  entityId,
  entity,
  entityCountry,
  onClose,
  onSuccess,
}: {
  entityId?: string;
  entity: 'supplier' | 'company';
  entityCountry: string;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const [validationSchema, setValidationSchema] = useState({});
  const [bankDetailsFromBackend, setBankDetailsFromBackend] = useState<{
    [key: string]: string;
  }>({});
  const [currentCountry, setCurrentCountry] = useState('');
  const [upsertBankAccount, { loading }] = useUpsertBankAccount();

  const { values, setErrors, setFieldValue, errors, setValues, handleSubmit } =
    useFormik<BankDialogFormData>({
      initialValues: {
        currency: DEFAULT_CURRENCY,
        bank_country: entityCountry || DEFAULT_COUNTRY,
        bank_address: '',
        bank_name: '',
        bic_swift: '',
        payment_type: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        const payment_type = find(
          requirementCombinations,
          (combination: { [x: string]: string }) =>
            combination.combination_id === values.combination_id
        )?.payment_type as string;
        upsertBankAccount({
          variables: {
            bank_account_data: {
              ...values,
              payment_type,
              [entity]: entityId,
            },
          },
          onCompleted: handleOnComplete,
        });
      },
      onReset: () => {},
    });

  const { getBankData } = useGetBankDetails();
  const showNotificationMessage = useToastNotification();
  const currencies = useGetCurrencies();

  const { paymentRequirements, isSepaPayment } = usePaymentsRequirements(
    values.currency,
    entityCountry || DEFAULT_COUNTRY,
    values.bank_country
  );

  const { requirementCombinations, requirementCombinationsType } =
    getPaymentRequirementsCombinations(
      paymentRequirements || [],
      isSepaPayment as boolean
    );

  const paymentRequirementsFields = getPaymentRequirementsDependencySchema(
    requirementCombinations
  );

  const bankFields = useMemo(() => {
    return paymentRequirementsFields?.[
      indexOf(
        requirementCombinationsType?.map(
          (c: { payment_type: string; combination_id: string }) =>
            c.payment_type
        ),
        values.payment_type
      )
    ];
  }, [
    paymentRequirementsFields,
    requirementCombinationsType,
    values.payment_type,
  ]);
  const onInvalidBankData = (error: ApolloError) => {
    const { message } = error;
    if (message.startsWith('invalid_')) {
      const bankIdentifyingCode = getBankIdentifyingCode(values);
      setErrors({
        ...errors,
        [bankIdentifyingCode.identifierType]: `${startCase(message)}`,
      });
    }
  };
  useQuery(getBankDetailsQuery, {
    onCompleted: (data) => {
      updateBankData(data?.banking?.bank_details);
    },
    onError: onInvalidBankData,
    skip: !isValidBankIdentifyingCode(values, requirementCombinations),
    variables: {
      ...getBankIdentifyingCode(values),
    },
    errorPolicy: 'all',
  });
  useEffect(
    () => {
      if (paymentRequirements) {
        const validationSchema = reduce(
          paymentRequirements,
          (acc: any, requirement: { [key: string]: string }) => {
            omit(requirement, ['payment_type', 'combination_id']);
            merge(acc, requirement);
            return acc;
          },
          {}
        );
        const requiredFields = pick(validationSchema, bankFields);
        setValidationSchema(createValidationSchema(requiredFields));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentRequirements, values.payment_type]
  );

  const formatErrorMessage = (key: string, error?: string) => {
    if (!error) {
      return '';
    }
    if (isArray(error)) {
      const message = map(error, (err: string) => {
        return err?.split(':')[1];
      });
      return message?.join(', ')?.replace(`${key}`, fieldTitle[key]);
    }
    return error;
  };

  const handleOnComplete = (data: UpsertBankAccountMutationType) => {
    const allBankAccountErrors =
      data.django?.upsert_bank_account?.errors?.all?.bank_account;
    const currentCombinationBankAccountErrors =
      data.django?.upsert_bank_account?.errors?.[values.combination_id]
        ?.bank_account;
    const supplierHasErrors = !isEmpty(
      data?.django?.upsert_bank_account?.errors?.[values.combination_id]
        ?.beneficiary
    );
    if (supplierHasErrors) {
      showNotificationMessage({
        title: 'Something wrong with the provided supplier information',
        type: 'error',
      });
    }
    if (data.django?.upsert_bank_account?.bank_account) {
      showNotificationMessage({
        title: 'Bank added successfully',
        type: 'success',
      });
      onSuccess();
      onClose();
    }
    if (some([allBankAccountErrors, currentCombinationBankAccountErrors])) {
      const allExtraErrors = getExtraErrors(allBankAccountErrors);
      const currentExtraErrors = getExtraErrors(
        currentCombinationBankAccountErrors
      );
      setErrors({ ...allExtraErrors, ...currentExtraErrors });
    }
  };

  const updateBankData = useCallback(
    (newBankDetails: { [key: string]: string }) => {
      setBankDetailsFromBackend(newBankDetails);
      const countryDontMatch =
        values?.bank_country !==
        newBankDetails?.bank_country_iso?.toLowerCase();
      setErrors({});
      if (countryDontMatch) {
        const bankIdentifyingCode = getBankIdentifyingCode(values);
        setErrors({
          ...errors,
          [bankIdentifyingCode.identifierType]: `This ${startCase(
            bankIdentifyingCode?.identifierType
          )} is not in ${getCountryName(values.bank_country)}`,
        });
      }

      const bank_address = newBankDetails?.bank_address
        ? `${newBankDetails.bank_address}, ${
            newBankDetails.bank_city || newBankDetails.bank_state
          }, ${newBankDetails?.bank_country}`
        : '';
      const updated_data = {
        ...values,
        bank_address,
        bank_name: newBankDetails?.bank_name || values.bankName,
        bic_swift:
          newBankDetails?.identifier_type === 'iban'
            ? newBankDetails?.bic_swift
            : values.bic_swift,
      };
      setValues({
        ...values,
        ...updated_data,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, bankDetailsFromBackend?.bic_swift, errors]
  );

  const onChangeBankCountry = (selectedBankCountry: string) => {
    // Set the 'bank_country' field value
    setFieldValue('bank_country', selectedBankCountry);

    // Get the bank identifying code from the form values
    const bankIdentifyingCode = getBankIdentifyingCode(values);
    // Check if the 'bank_country' has changed and there is a bank identifier value
    if (
      values.bank_country !== currentCountry &&
      !!bankIdentifyingCode?.identifierValue
    ) {
      // Update the current country and fetch bank data
      setCurrentCountry(values.bank_country);
      getBankData({
        variables: {
          ...getBankIdentifyingCode(values),
        },
        onCompleted: (data) => updateBankData(data?.banking?.bank_details),
        onError: (error) => {
          onInvalidBankData(error);
        },
        errorPolicy: 'all',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          marginBlock: 2,
        }}>
        <FormControl key="currency">
          <InputLabel id="currency">Currency</InputLabel>
          <Select
            labelId="currency"
            name="currency"
            label="Currency"
            onChange={(e) => {
              setFieldValue('currency', e.target.value);
            }}
            value={values.currency}>
            {currencies.map((currency: string) => (
              <MenuItem key={currency} value={currency}>
                {currency.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          options={COUNTRIES_OPTIONS}
          value={
            COUNTRIES_OPTIONS.find(
              (item) => item.value === values.bank_country
            ) || null
          }
          fullWidth
          getOptionLabel={(option) => option.title || ''}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          onChange={(_e, item) => {
            const selectedBankCountry = item && item.value ? item.value : '';
            onChangeBankCountry(selectedBankCountry);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              name="bank_country"
              label={'Bank country'}
              error={Boolean(errors.bank_country)}
              helperText={errors.bank_country}
            />
          )}
        />
        <FormControl key="payment_type">
          <InputLabel id="payment_type">Payment type</InputLabel>
          <Select
            labelId="payment_type"
            name="payment_type"
            label="payment_type"
            onChange={(e) => {
              const index = indexOf(
                requirementCombinationsType?.map(
                  (c: { payment_type: string; combination_id: string }) =>
                    c.payment_type
                ),
                e.target.value
              );
              setFieldValue('payment_type', e.target.value);
              setFieldValue(
                'combination_id',
                requirementCombinationsType[index].combination_id
              );
            }}
            value={values.payment_type}>
            {requirementCombinationsType?.map(
              (combination: {
                payment_type: string;
                combination_id: string;
              }) => (
                <MenuItem
                  key={combination.combination_id}
                  value={combination.payment_type}>
                  {combination.payment_type}
                </MenuItem>
              )
            )}
          </Select>
          <FormHelperText>
            Local payments are usually faster and cheaper.
          </FormHelperText>
        </FormControl>
        {values.payment_type && (
          <>
            {bankFields?.map((paymentRequirementField: string) => (
              <TextField
                key={paymentRequirementField}
                name={paymentRequirementField}
                label={fieldTitle[paymentRequirementField]}
                value={values[paymentRequirementField]}
                onChange={(e) => {
                  setFieldValue(paymentRequirementField, e.target.value);
                  const bankIdentifyingCode = getBankIdentifyingCode({
                    ...values,
                    [paymentRequirementField]: e.target.value,
                  });
                  if (
                    values.bank_country !== currentCountry &&
                    !!bankIdentifyingCode?.identifierValue
                  ) {
                    setCurrentCountry(values.bank_country);
                    getBankData({
                      variables: {
                        ...getBankIdentifyingCode({
                          ...values,
                          [paymentRequirementField]: e.target.value,
                        }),
                      },
                      onCompleted: (data) =>
                        updateBankData(data?.banking?.bank_details),
                      onError: (error) => {
                        onInvalidBankData(error);
                      },
                      errorPolicy: 'all',
                    });
                  }
                }}
                required
                error={Boolean(errors[paymentRequirementField])}
                helperText={
                  errors?.[paymentRequirementField] &&
                  formatErrorMessage(
                    paymentRequirementField,
                    errors?.[paymentRequirementField]
                  )
                }
              />
            ))}
            {!['Bankgiro', 'Plusgiro'].includes(values.payment_type) && (
              <>
                <TextField
                  name="bank_name"
                  label="Bank name"
                  disabled={!!bankDetailsFromBackend?.bank_name}
                  fullWidth
                  error={Boolean(errors.bank_name)}
                  helperText={formatErrorMessage('bank_name', errors.bank_name)}
                  value={
                    bankDetailsFromBackend?.bank_name || values.bank_name || ''
                  }
                  onChange={(e) => {
                    setFieldValue('bank_name', e.target.value);
                  }}
                />
                <TextField
                  name="bank_address"
                  label="Bank address"
                  disabled={!!bankDetailsFromBackend?.bank_address}
                  fullWidth
                  error={Boolean(errors.bank_address)}
                  helperText={formatErrorMessage(
                    'bank_address',
                    errors.bank_address
                  )}
                  value={
                    bankDetailsFromBackend?.bank_address ||
                    values.bank_address ||
                    ''
                  }
                  onChange={(e) => {
                    setFieldValue('bank_address', e.target.value);
                  }}
                />
              </>
            )}
          </>
        )}
      </Box>
      <DialogActions>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          size="large"
          variant="contained"
          disabled={!isEmpty(errors) || !values.payment_type || loading}>
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

export default BankAccountForm;
