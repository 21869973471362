import { SearchField } from '@/components';
import { ExportTransactionsButton } from '@/components/Transactions/ExportTransactionsButton';
import { TRANSACTION_TYPE } from '@/constants';
import useSearchTerm from '@/hooks/useSearchTerm';
import { Stack } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QueryFilterField,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { isEmpty, isNaN, toNumber } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableFilters } from './TableFilters';
import { TransactionsTable } from './TransactionsTable';

export const Transactions = () => {
  const { id } = useParams();
  const companyId =
    isNaN(toNumber(id)) || isEmpty(id) ? undefined : toNumber(id);

  const { searchTerm, setSearchTerm } = useSearchTerm('transactionsSearchTerm');

  const [filterFields, setFilterFields] = useState<QueryFilterField[]>([
    {
      type: 'filter',
      name: 'type',
      comparator: FilterComparator.IN,
      comparisonValue: [TRANSACTION_TYPE.PAYMENT],
    },
  ]);

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'invoice__invoice_number',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'invoice__order_id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(searchTerm)
          ? null
          : toNumber(searchTerm),
    },
  ];
  const filters: Filter[] = [
    ...searchFields,
    ...filterFields,
    {
      type: 'filter',
      name: 'invoice__order__importer_id',
      comparator: FilterComparator.EQUAL,
      comparisonValue: companyId,
    },
  ];

  return (
    <Stack gap={2}>
      <Row>
        <Col xs={12} sm>
          <SearchField
            placeholder={'Search by invoice #, or order #'}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Col>
        <Col xs={12} sm="auto">
          <TableFilters
            onSubmit={setFilterFields}
            appliedFilterFieldsCount={filterFields.length}
          />
        </Col>
        <Col xs={12} sm="auto">
          <ExportTransactionsButton filters={filters} />
        </Col>
      </Row>
      <TransactionsTable filters={filters} />
    </Stack>
  );
};
