import { useFinanciersFilter } from '@/hooks';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { DataGrid, GridColumns, gridClasses } from '@mui/x-data-grid';
import { COUNTRIES_ENUM } from '@treyd-io/core/constants/country';
import { getCountryName } from '@treyd-io/core/utils/country';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';
import { difference, intersection, union } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { editNotificationGroup } from '@/api';
import {
  Merchant,
  getNotificationsMerchantsList,
} from '@/schemas/getNotificationsMerchantsList';
import CustomToolbar from './CustomToolbar';

export interface AddMerchantDialogProps {
  open: boolean;
  toggle: () => void;
  notificationGroupId: number;
  refetch: () => void;
  merchantList: number[];
}
const AddMerchantDialog = ({
  open,
  toggle,
  notificationGroupId,
  refetch,
  merchantList,
}: AddMerchantDialogProps) => {
  const { availableFinanciersNames, loading: financierLoading } =
    useFinanciersFilter();
  const [filters, setFilters] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchList, setSearchList] = useState<[]>([]);
  const [selectionModel, setSelectionModel] = useState<number[]>(merchantList);
  const [hiddenSelectedIds, setHiddenSelectedIds] = useState<number[]>([]);
  const showNotificationMessage = useToastNotification();
  const [callGetMerchants, { data, loading }] = useLazyQuery(
    getNotificationsMerchantsList(filters)
  );

  const selectedIds = useMemo(
    () => union(selectionModel, hiddenSelectedIds),
    [selectionModel, hiddenSelectedIds]
  );

  const rows = data?.orders_companies?.map((merchant: any) => ({
    id: merchant.id,
    name: merchant.name,
    financier__name: merchant.financier?.name,
    country: merchant.country,
  }));

  const columns: GridColumns<Merchant> = [
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
    },
    {
      field: 'financier__name',
      headerName: 'Financiers',
      width: 180,
    },
    {
      field: 'country',
      headerName: 'Market',
      width: 180,
      renderCell: (params) => getCountryName(params.value),
    },
  ];

  const requestSearch = useCallback(
    (searchValue: string) => {
      setSearchTerm(searchValue);
      if (searchValue) {
        const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
        const visibleMerchants = rows?.filter((o: any) =>
          Object.keys(o).some((k: any) => searchRegex.test(o[k]?.toString()))
        );
        const newSelectionModel = intersection(
          visibleMerchants?.map((o: any) => o.id),
          selectionModel
        );
        setSearchList(visibleMerchants);
        setSelectionModel(newSelectionModel);
        const diff = difference(selectedIds, newSelectionModel);
        setHiddenSelectedIds(diff);
      } else {
        setSelectionModel(union(selectionModel, hiddenSelectedIds));
        setHiddenSelectedIds([]);
      }
    },
    [rows, selectionModel, hiddenSelectedIds, selectedIds]
  );

  const onClickSave = async () => {
    try {
      await editNotificationGroup(notificationGroupId, {
        targets_type: 'company',
        targets_ids: selectedIds,
      });
      showNotificationMessage({
        title: 'Merchants added',
        type: 'success',
      });
      refetch();
      toggle();
    } catch (error) {
      showNotificationMessage({
        title: "Couldn't add Merchants",
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (!financierLoading) {
      callGetMerchants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financierLoading]);

  return (
    <Dialog
      open={open}
      onClose={toggle}
      PaperProps={{
        sx: { borderRadius: 3 },
      }}>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Add merchants</Typography>
          <IconButton onClick={toggle}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          sx={(theme) => ({ marginBlockEnd: theme.spacing(2) })}>
          Add which merchants you would like to see the notification. You have
          the possibility to filter the list based on markets and financiers.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={(theme) => ({ marginBlockEnd: theme.spacing(2) })}>
          Filter & search
        </Typography>
        <CustomToolbar
          filters={filters}
          setFilters={setFilters}
          searchTerm={searchTerm}
          searchPlaceholder="Search for merchants"
          setSearchTerm={requestSearch}
          filterList={[
            {
              field: 'country',
              options: COUNTRIES_ENUM as string[],
              label: 'Markets',
            },
            {
              field: 'financier__name',
              options: availableFinanciersNames,
              label: 'Financiers',
            },
          ]}
        />
        <DataGrid
          loading={loading}
          pageSize={5}
          rowsPerPageOptions={[5]}
          rows={searchTerm ? searchList : rows || []}
          columns={columns}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within, & .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              { outline: 'none' },
          }}
          onSelectionModelChange={(newSelectionModel: any) => {
            if (
              !(
                !searchTerm &&
                selectionModel.length > 0 &&
                newSelectionModel.length === 0
              )
            ) {
              return setSelectionModel(newSelectionModel);
            }
          }}
          selectionModel={selectionModel}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!selectedIds.length}
          size="large"
          onClick={onClickSave}
          variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddMerchantDialog;
