import axios from 'axios';
import { isEmpty } from 'lodash';
import { LAGER_API_HOST } from '../constants/api';
import { REQUEST_METHOD } from '../constants/request';
import { RequestOptions } from '../types/requests';
import { getJWT } from './jwt';

export async function request<ResponseDataType>({
  noAuth,
  extHost,
  ...options
}: RequestOptions) {
  if (extHost) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    };
    const res = await axios.request<ResponseDataType>(options);
    return res;
  }

  if (!noAuth) {
    if (
      isEmpty(options?.headers?.['Authorization']) &&
      isEmpty(options?.headers?.['AUTHORIZATIONJWT'])
    ) {
      const { accessToken: auth } = getJWT();
      options = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: auth,
          AUTHORIZATIONJWT: auth,
        },
      };
    }
  }

  if (isEmpty(options.baseURL)) {
    if (options.url) {
      options.baseURL = LAGER_API_HOST;
    }
  }

  const res = await axios.request<ResponseDataType>({
    ...options,
  });

  return res;
}

export function save<ResponseDataType>(
  url: string,
  data: unknown,
  method: REQUEST_METHOD,
  options: RequestOptions = {}
) {
  return request<ResponseDataType>({
    baseURL: options.baseURL,
    method,
    url,
    headers: {
      'content-type': 'application/json',
    },
    data,
    ...options,
  });
}

export function get<ResponseDataType>(
  url: string,
  options: RequestOptions = {}
) {
  const { id, params, noToast, ...rest } = options;

  return request<ResponseDataType>({
    method: 'GET',
    url: id ? `${url}/${id}` : url,
    params,
    noToast,
    ...rest,
  });
}
