import { DEFAULT_COUNTRY, DEFAULT_CURRENCY } from '@/constants';
import { getBeneficiaryRequirementsQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import { isEuMember } from '@treyd-io/core/utils/country';
import { filter } from 'lodash';

import { useMemo } from 'react';

export const usePaymentsRequirements = (
  currency: string,
  beneficiaryCountry: string,
  bankCountry: string
) => {
  const { data, loading } = useQuery(getBeneficiaryRequirementsQuery, {
    variables: {
      currency: currency?.toUpperCase() || DEFAULT_CURRENCY,
      beneficiaryCountry:
        beneficiaryCountry?.toUpperCase() ||
        bankCountry?.toUpperCase() ||
        DEFAULT_COUNTRY,
      bankAccountCountry:
        bankCountry?.toUpperCase() ||
        beneficiaryCountry?.toUpperCase() ||
        DEFAULT_COUNTRY,
    },
  });

  const { paymentRequirements, isSepaPayment } = useMemo(() => {
    let paymentRequirements = data?.banking?.beneficiary_requirements;
    const isSepaPayment =
      currency?.toLowerCase() === 'eur' &&
      bankCountry &&
      isEuMember(bankCountry.toUpperCase());
    if (isSepaPayment) {
      const regularRequirements = filter(
        paymentRequirements,
        (requirement) => requirement.payment_type === 'regular'
      );
      paymentRequirements = regularRequirements?.length
        ? regularRequirements
        : paymentRequirements;
    }
    return { paymentRequirements, isSepaPayment };
  }, [data, currency, bankCountry]);

  return {
    paymentRequirements,
    loading,
    isSepaPayment,
  };
};
