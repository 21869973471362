import { DEFAULT_GRID_COL_DEF, IMPERSONATE_WITH_EMAIL_LINK } from '@/constants';
import { RolesType, TableRowType, TreydUser } from '@/types';
import { Onfido } from '@/types/onfido';
import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
} from '@mui/x-data-grid';
import { formatDate } from '@treyd-io/core/utils/date';
import { openURL } from '@treyd-io/core/utils/openUrl';
import { CopyButton } from '@treyd-io/treyd-ui/components/CopyButton';
import ResponsiveButton from '@treyd-io/treyd-ui/components/ResponsiveButton';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import PassportIcon from '@treyd-io/treyd-ui/icons/PassportIcon';
import PersonIcon from '@treyd-io/treyd-ui/icons/PersonIcon';
import PlusIcon from '@treyd-io/treyd-ui/icons/PlusIcon';
import RefreshIcon from '@treyd-io/treyd-ui/icons/RefreshIcon';
import TrashTwoIcon from '@treyd-io/treyd-ui/icons/TrashTwoIcon';
import UserIcon from '@treyd-io/treyd-ui/icons/UserIcon';
import { chain, find } from 'lodash';
import { useMemo, useState } from 'react';
import { OnfidoCheckDialog } from './OnfidoCheckDialog';

interface CompanyUsersTableProps {
  companyId?: string;
  companyUsers?: TreydUser[];
  attorneyIds: number[];
  isKycSubmitted: boolean;
  toggleInviteModal: () => void;
  toggleConfirmModal: () => void;
  toggleDeleteModal: () => void;
  toggleMainContactModal: () => void;
  setUser: (user: TreydUser) => void;
  mainContactId?: number;
}

const CompanyUsersTable = (props: CompanyUsersTableProps) => {
  const {
    companyId,
    companyUsers,
    attorneyIds,
    isKycSubmitted,
    toggleInviteModal,
    toggleConfirmModal,
    toggleDeleteModal,
    toggleMainContactModal,
    setUser,
    mainContactId,
  } = props;

  const [onfidoReport, setOnfidoReport] = useState<Onfido.Report | null>(null);
  const showNotificationMessage = useToastNotification();

  const getUserStatus = (user: TreydUser): TableRowType['status'] => {
    if (!user?.invitation_accepted_at) {
      return { label: 'Invite pending', color: 'primary' };
    }
    if (user?.identity_verified) {
      return { label: 'Identity Verified', color: 'success' };
    }
    return { label: 'Unverified', color: 'warning' };
  };

  const getUserRoles = (user: TreydUser, attorneyIds: number[]) => {
    const roles: RolesType[] = [];
    if (user?.is_ubo && isKycSubmitted) {
      roles.push({ label: 'UBO', color: 'default', variant: 'filled' });
    }
    if (user?.can_sign_alone) {
      roles.push({ label: 'Can sign alone', color: 'success' });
    }
    if (user?.member) {
      roles.push({ label: 'Board', color: 'info' });
    }
    if (attorneyIds?.includes(user?.user_id as number)) {
      roles.push({ label: 'POA', color: 'warning' });
    }
    if (user?.id === mainContactId)
      roles.push({ label: 'Main contact', color: 'info' });

    return roles;
  };

  const usersRows: TableRowType[] = chain(companyUsers)
    .map((user: TreydUser) => ({
      ...user,
      id: user?.user_id,
      name: user?.name,
      email: user?.email,
      phone:
        user?.phone_no &&
        user?.phone_code &&
        `+${user?.phone_code}${user?.phone_no}`,
      role: getUserRoles(user, attorneyIds),
      status: getUserStatus(user),
      last_login:
        user?.last_login && formatDate(new Date(user?.last_login), 'datetime'),
      date_joined:
        user?.date_joined &&
        formatDate(new Date(user?.date_joined), 'datetime'),
      actions: {
        resend: !user?.invitation_accepted_at,
      },
      phone_confirmed_at: user?.phone_confirmed_at,
    }))
    .orderBy((event) => event.role.length, ['desc'])
    .value();

  const handleResend = async (user: TableRowType) => {
    setUser(user);
    toggleConfirmModal();
  };

  const handleDelete = async (user: TableRowType) => {
    setUser(user);
    toggleDeleteModal();
  };

  const handleUpdateMainContact = async (user: TableRowType) => {
    setUser(user);
    toggleMainContactModal();
  };

  const handleOnfidoCheckDialogOpen = (onfidoReport: Onfido.Report) => {
    setOnfidoReport(onfidoReport);
  };

  const handleOnfidoCheckDialogClose = () => {
    setOnfidoReport(null);
  };

  const columns: GridColDef<TreydUser>[] = useMemo(
    () => [
      {
        ...DEFAULT_GRID_COL_DEF,
        field: 'name',
        headerName: 'Full name',
        minWidth: 150,
        type: 'string',
        renderCell: (params: GridCellParams) => (
          <Typography variant="body2">{params.value}</Typography>
        ),
      },
      {
        ...DEFAULT_GRID_COL_DEF,
        field: 'email',
        headerName: 'Email',
        type: 'string',
        minWidth: 100,
        renderCell: (params) => (
          <Stack direction="row" alignItems="center" gap={0.5} minWidth={0}>
            <Typography variant="body2" noWrap>
              {params.value}
            </Typography>
            {params.value && (
              <CopyButton
                value={params.value}
                iconProps={{ sx: { color: 'info.main' }, fontSize: 'small' }}
                buttonProps={{
                  size: 'small',
                  onClick: () =>
                    showNotificationMessage({
                      title: 'Copied to clipboard',
                      type: 'success',
                      duration: 500,
                      position: 'absolute',
                    }),
                }}
              />
            )}
          </Stack>
        ),
      },
      {
        ...DEFAULT_GRID_COL_DEF,
        field: 'phone',
        headerName: 'Phone',
        type: 'string',
        minWidth: 100,
        renderCell: (params: GridCellParams) => (
          <Stack direction="row" alignItems="center" gap={0.5} minWidth={0}>
            <Stack direction="row" gap={0.5} alignItems="center" minWidth={0}>
              {!!params.row.phone_confirmed_at && (
                <Tooltip title="Phone verified">
                  <Stack justifyContent="center" alignItems="center">
                    <CheckCircleIcon color="success" fontSize="small" />
                  </Stack>
                </Tooltip>
              )}
              <Typography variant="body2" noWrap>
                {params.value}
              </Typography>
            </Stack>
            {params.value && (
              <CopyButton
                value={params.value}
                iconProps={{ sx: { color: 'info.main' }, fontSize: 'small' }}
                buttonProps={{
                  size: 'small',
                  onClick: () =>
                    showNotificationMessage({
                      title: 'Copied to clipboard',
                      type: 'success',
                      duration: 500,
                      position: 'absolute',
                    }),
                }}
              />
            )}
          </Stack>
        ),
      },
      {
        field: 'role',
        headerName: 'Role',
        minWidth: 150,
        maxWidth: 300,
        flex: 1,
        renderCell: (params: GridCellParams) => (
          <Stack direction="row" gap={1} paddingY={1} overflow="scroll">
            {params.value.map((role: RolesType) => (
              <Chip
                key={role.label}
                label={role.label}
                color={role.color}
                variant="standard"
              />
            ))}
          </Stack>
        ),
      },
      {
        ...DEFAULT_GRID_COL_DEF,
        field: 'status',
        headerName: 'Status',
        type: 'string',
        minWidth: 125,
        renderCell: (params: GridCellParams<TableRowType['status']>) => (
          <Typography
            variant="body2"
            sx={(theme) => ({
              color:
                params.value?.color && theme.palette[params.value?.color].main,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            })}>
            {params.value?.label}
          </Typography>
        ),
      },
      {
        ...DEFAULT_GRID_COL_DEF,
        field: 'date_joined',
        headerName: 'Joined at',
        type: 'string',
        minWidth: 145,
      },
      {
        ...DEFAULT_GRID_COL_DEF,
        field: 'last_login',
        headerName: 'Last logged in',
        type: 'string',
        minWidth: 145,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: function Actions(params: GridCellParams) {
          const onfidoReports =
            params?.row?.onfido_applicant?.onfido_check?.[0]?.reports_payload
              ?.reports;
          const documentReport = find(
            onfidoReports,
            (report: Onfido.Report) => {
              return report.name === Onfido.REPORT_NAME.DOCUMENT;
            }
          );

          const actions = [
            <GridActionsCellItem
              icon={<PersonIcon />}
              label="Impersonate"
              showInMenu
              onClick={() =>
                openURL(
                  IMPERSONATE_WITH_EMAIL_LINK(params?.row?.email, companyId),
                  '_blank'
                )
              }
            />,
            <GridActionsCellItem
              icon={<UserIcon />}
              label="Main contact"
              disabled={
                //disable if user is already main contact or if user didn't accept invitation yet
                params.row?.id === mainContactId || params.row?.actions?.resend
              }
              showInMenu
              onClick={() => handleUpdateMainContact(params.row)}
            />,
            <GridActionsCellItem
              icon={<TrashTwoIcon />}
              label="Delete"
              showInMenu
              onClick={() => handleDelete(params.row)}
            />,
          ];

          if (params.row?.actions?.resend) {
            actions.push(
              <GridActionsCellItem
                icon={<RefreshIcon />}
                showInMenu
                label="Resend invitation"
                onClick={() => handleResend(params.row)}
              />
            );
          }
          if (documentReport?.properties) {
            actions.push(
              <GridActionsCellItem
                icon={<PassportIcon />}
                showInMenu
                label="Onfido report"
                onClick={() => handleOnfidoCheckDialogOpen(documentReport)}
              />
            );
          }
          return actions;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mainContactId]
  );

  return (
    <Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBlockEnd: theme.spacing(1.5),
        })}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1.5),
          })}>
          <Typography variant="h5">Registered users</Typography>
          <Typography variant="body2">
            This is the list of all people associated with this company
            registered on our application.
          </Typography>
        </Box>
        <ResponsiveButton
          variant="outlined"
          color="secondary"
          onClick={() => toggleInviteModal()}
          sx={{
            alignSelf: 'flex-start',
          }}
          buttonProps={{
            startIcon: <PlusIcon />,
            children: 'Add user',
          }}
          iconButtonProps={{
            children: <PlusIcon />,
          }}
        />
      </Box>
      <OnfidoCheckDialog
        onClose={handleOnfidoCheckDialogClose}
        report={onfidoReport}
      />
      <DataGrid
        disableSelectionOnClick
        rows={usersRows}
        columns={columns}
        pageSize={5}
        autoHeight={true}
        sx={{
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
        getRowHeight={() => 'auto'}
      />
    </Box>
  );
};

export default CompanyUsersTable;
