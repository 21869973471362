import { PLATFORM_HOST } from './platform-links';

export const EU_COMPANY_AGREEMENTS = {
  version_1: {
    link: `${PLATFORM_HOST}/legal/Treyd_Terms_and_Conditions_Payment_Service_v1.pdf`,
    title: 'Account agreement',
  },
  version_2: {
    link: `${PLATFORM_HOST}/legal/Treyd_Terms_and_Conditions_Payment_Service_v2.pdf`,
    title: 'Account agreement',
  },
  version_3: {
    link: `${PLATFORM_HOST}/legal/General_Services_Agreement_EU.pdf`,
    title: 'General services agreement',
  },
};

export const UK_COMPANY_AGREEMENTS = {
  version_1: {
    link: `${PLATFORM_HOST}/legal/General_Services_Agreement_UK.pdf`,
    title: 'General service agreement',
  },
};

export const TOS = {
  link: `${PLATFORM_HOST}/legal/Platform_Terms_And_Conditions.pdf`,
  title: 'Terms of services',
};
