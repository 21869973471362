import { PAYMENT_TYPES, PRIORITY_PAYMENT, REGULAR_PAYMENT } from '@/constants';
import { isEmpty, keys, map, mapValues, omit, reduce, startCase } from 'lodash';
import * as yup from 'yup';
import { BankDialogFormData } from './BankAccountForm';

export const DEFAULT_COUNTRY = 'cn';
export const DEFAULT_CURRENCY = 'usd';

function getRequirementCombinations(
  requirementCombinations: {
    [x: string]: string;
  }[],
  isSepaPayment: boolean
) {
  // Count the occurrences of REGULAR_PAYMENT and PRIORITY_PAYMENT types
  const localCount = countPaymentType(
    requirementCombinations,
    REGULAR_PAYMENT.value
  );
  const swiftCount = countPaymentType(
    requirementCombinations,
    PRIORITY_PAYMENT.value
  );

  return (requirementCombination: { [key: string]: string }) => {
    // Determine the payment type
    const paymentType = getPaymentType(requirementCombination);

    // Determine the unique requirement
    const uniqueRequirement = getUniqueRequirement(requirementCombination);

    // Generate the payment_type label
    const paymentTypeLabel = generatePaymentTypeLabel(
      paymentType,
      uniqueRequirement,
      localCount,
      swiftCount,
      isSepaPayment
    );
    return {
      payment_type: paymentTypeLabel,
      combination_id: requirementCombination.combination_id,
    };
  };
}

// Helper function to count occurrences of a payment type
function countPaymentType(requirementCombinations: any, paymentType: string) {
  return requirementCombinations.filter((requirementCombination: any) =>
    requirementCombination.payment_type?.includes(paymentType)
  ).length;
}

// Helper function to get the payment type object
function getPaymentType(requirementCombination: any) {
  return (
    PAYMENT_TYPES.find((type) =>
      requirementCombination.payment_type?.includes(type.value)
    ) || {
      value: requirementCombination.payment_type,
      label: startCase(requirementCombination.payment_type),
    }
  );
}

// Helper function to get the unique requirement
function getUniqueRequirement(requirementCombination: any) {
  if (requirementCombination.sort_code) return 'Sort Code';
  if (requirementCombination.clabe) return 'CLABE';
  if (requirementCombination.cnaps) return 'CNAPS';
  if (requirementCombination.aba) return 'ABA';
  if (requirementCombination.iban) return 'IBAN';
  if (requirementCombination.acct_number) return 'Account Number';
  return '';
}

// Helper function to generate the payment_type label
function generatePaymentTypeLabel(
  paymentType: any,
  uniqueRequirement: string,
  localCount: number,
  swiftCount: number,
  isSepaPayment: boolean
) {
  const label = isSepaPayment ? 'SEPA' : paymentType.label;
  if (
    paymentType.value === REGULAR_PAYMENT.value &&
    uniqueRequirement &&
    localCount > 1
  ) {
    return `${label} (${uniqueRequirement})`;
  }
  if (
    paymentType.value === PRIORITY_PAYMENT.value &&
    uniqueRequirement &&
    swiftCount > 1
  ) {
    return `${label} (${uniqueRequirement})`;
  }
  return label;
}

const getRequiredFields = (
  requirement: { [x: string]: string },
  excludedProperties: string[]
) => keys(requirement).filter((key) => !excludedProperties.includes(key));

export const getPaymentRequirementsDependencySchema = (
  paymentRequirements: {
    [x: string]: string;
  }[]
) => {
  const excluded_fields = [
    'payment_type',
    'bank_country',
    'currency',
    'combination_id',
    'bank_name',
    'bank_address',
  ];
  return map(
    paymentRequirements,
    (requirementCombination: { [key: string]: string }) =>
      getRequiredFields(requirementCombination, excluded_fields)
  );
};

export const getPaymentRequirementsCombinations = (
  paymentRequirements: { [key: string]: string }[],
  isSepaPayment: boolean
) => {
  const requirementCombinations = map(
    paymentRequirements,
    (requirementCombination: { [key: string]: string }) => {
      const updatedResult = omit(requirementCombination, [
        'beneficiary_entity_type',
        'beneficiary_postcode',
        'beneficiary_state_or_province',
        'beneficiary_company_name',
        'beneficiary_country',
        'beneficiary_address',
        'beneficiary_city',
      ]);
      return updatedResult;
    }
  );

  const requirementCombinationsType = map(
    paymentRequirements,
    getRequirementCombinations(requirementCombinations, isSepaPayment)
  );

  return {
    requirementCombinations,
    requirementCombinationsType,
  };
};

export const getExtraErrors = (extra_errors: { [key: string]: string }) => {
  if (isEmpty(extra_errors)) {
    return {};
  }
  //TODO: remove any
  return mapValues(extra_errors, (value) => [value]) as any;
};

export function createValidationSchema(fields: { [key: string]: RegExp }) {
  return yup.object().shape(
    mapValues(fields, (regex, key) => {
      return yup
        .string()
        .required(`${fieldTitle[key]} is required`)
        .matches(regex, 'Validation error');
    })
  );
}

interface BankIdentifyingCode {
  identifierType: string;
  identifierValue: string;
  bankCountry: string;
  accountNumber: string;
}

export const getBankIdentifyingCode = (
  bankFormData: BankDialogFormData
): BankIdentifyingCode => {
  const identifyingCodes = [
    'iban',
    'bic_swift',
    'sort_code',
    'aba',
    'bsb_code',
    'routing_number',
    'cnaps',
    'ifsc',
  ];
  return reduce(
    identifyingCodes,
    (result, code) => {
      const value = bankFormData?.[code];
      if (value && isEmpty(result)) {
        return {
          identifierType: code,
          identifierValue: value,
          bankCountry: bankFormData?.bank_country,
          accountNumber: bankFormData?.iban || bankFormData?.acct_number || '',
        };
      }
      return result;
    },
    {} as BankIdentifyingCode
  );
};

export const isValidBankIdentifyingCode = (
  formData: any,
  requirementCombinations: any
) => {
  const combination = requirementCombinations.find(
    (combination: any) =>
      combination.combination_id === formData?.combination_id
  );
  const bankIdentifyingCode = getBankIdentifyingCode(formData);
  const codeType = bankIdentifyingCode?.identifierType;
  if (!codeType) {
    return false;
  }
  const regex = new RegExp(combination?.[codeType]);
  return regex.test(bankIdentifyingCode?.identifierValue);
};

export const fieldTitle: { [key: string]: string } = {
  acct_number: 'Account number',
  bic_swift: 'BIC/SWIFT',
  bank_account_holder_name: 'Account holder name',
  iban: 'IBAN',
  bank_code: 'Bank code',
  plusgiro_number: 'Plusgiro number',
  bankgiro_number: 'Bankgiro number',
  sort_code: 'Sort code',
  aba: 'ABA',
  bsb_code: 'BSB code',
  routing_number: 'Routing number',
  cnaps: 'CNAPS',
  ifsc: 'IFSC',
  clabe: 'CLABE',
  bank_address: 'Bank address',
  bank_name: 'Bank name',
};
