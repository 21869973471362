import {
  MerchantLimitStatusChip,
  MerchantWithLink,
  OrderStatusChip,
} from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { MAPPED_ORDER_STATUS, PAGE_SIZE_OPTIONS, ROUTES } from '@/constants';
import { useGetTableSettings } from '@/hooks';
import { useGetOrders } from '@/hooks/useGetOrders';
import { Order } from '@/schemas/getOrdersQuery';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toString } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export const OrdersTable = ({ filters }: { filters?: Filter[] }) => {
  const {
    ordersReviewAllOrdersTableSettings: {
      sortModel,
      pageSize,
      page,
      setSortModel,
      setPageSize,
      setPage,
    },
  } = useGetTableSettings();

  const { data, loading: isLoadingOrders } = useGetOrders({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: pageSize,
      offset: page * pageSize,
    },
  });

  const orders = data?.orders_orders || [];
  const rowCount = data?.orders_orders_aggregate?.aggregate?.count || 0;

  const noOrdersProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  const columns: GridColDef<Order>[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Order #',
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          const isDiscardedOrder =
            params.row?.mapped_status === MAPPED_ORDER_STATUS.DISCARDED;
          if (isDiscardedOrder)
            return (
              <Typography
                component="del"
                variant="body2"
                color="text.secondary">
                {params.value}
              </Typography>
            );
          return (
            <Typography
              component={Link}
              to={`${ROUTES.orderReview}/${params.id}`}
              noWrap
              variant="body2">
              {params.value}
            </Typography>
          );
        },
      },
      {
        field: 'mapped_status',
        headerName: 'Status',
        minWidth: 190,
        renderCell: ({ value }) => (
          <OrderStatusChip size="small" status={value} />
        ),
      },
      {
        field: 'importer__name',
        headerName: 'Merchant',
        minWidth: 200,
        renderCell: (params) => {
          const id = params.row?.importer?.id;
          const name = params.row?.importer?.name;
          const segment = params.row?.importer?.segment;
          return (
            name &&
            segment && (
              <MerchantWithLink id={id} name={name} segment={segment} />
            )
          );
        },
      },
      {
        field: 'importer__is_active',
        headerName: 'Merchant status',
        minWidth: 150,
        renderCell: (params) => {
          const isActive = params.row?.importer?.is_active;
          const orgNumber = params.row?.importer?.org_no;
          return (
            <MerchantLimitStatusChip
              size="small"
              isActive={isActive}
              orgNumber={orgNumber}
            />
          );
        },
      },
      {
        field: 'financier__name',
        headerName: 'Financier',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => params.row?.financier?.name,
      },
      {
        field: 'created',
        headerName: 'Creation date',
        minWidth: 150,
        renderCell: ({ value }) => {
          return value && formatDate(new Date(value), 'date');
        },
      },
      {
        field: 'active_payout__expected_payment_date',
        headerName: 'Expected payment date',
        minWidth: 150,
        renderCell: (params) => {
          const date = params.row?.active_payout?.expected_payment_date;
          return date && formatDate(new Date(date), 'date');
        },
      },
      {
        field: 'active_payout__expected_settlement_date_calc',
        headerName: 'Expected repayment date',
        minWidth: 150,
        renderCell: (params) => {
          const date = params.row?.active_payout?.expected_settlement_date_calc;
          return date && formatDate(new Date(date), 'date');
        },
      },
      {
        field: 'active_payout__actual_payment_date',
        headerName: 'Actual payment date',
        minWidth: 150,
        renderCell: (params) => {
          const date = params.row?.active_payout?.actual_payment_date;
          return date && formatDate(new Date(date), 'date');
        },
      },
      {
        field: 'active_payout__amount',
        headerName: 'Payout amount',
        minWidth: 150,
        flex: 1,
        cellClassName: 'tabular-numbers',
        renderCell: (params) => {
          const amount = params.row?.active_payout?.amount;
          const currency = params.row?.active_payout?.currency;
          return formatCurrency(amount, toString(currency));
        },
      },
      {
        field:
          'orders_totals_in_invoicing_currency__total_amount_inc_vat_in_invoicing_currency',
        headerName: 'Repayment amount',
        minWidth: 150,
        flex: 1,
        cellClassName: 'tabular-numbers',
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          const amount =
            params.row?.orders_totals_in_invoicing_currency
              ?.total_amount_inc_vat_in_invoicing_currency;
          const currency = params.row?.invoicing_currency;
          return formatCurrency(amount, toString(currency));
        },
      },
    ],
    []
  );
  return (
    <Stack flexGrow={1} width="100%">
      <DataGrid
        rows={orders}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        onPageChange={setPage}
        rowCount={rowCount}
        pageSize={pageSize}
        page={page}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        onPageSizeChange={setPageSize}
        sortModel={sortModel}
        loading={isLoadingOrders}
        onSortModelChange={setSortModel}
        components={{
          NoRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'Nothing here yet'}
              subtitle={'Orders will be displayed here'}
            />
          ),
          ...noOrdersProps,
        }}
        {...noOrdersProps}
      />
    </Stack>
  );
};
